// React
import React, { useState, useEffect, useCallback } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

// Mui icon
import ImageIcon from '@material-ui/icons/Image';

// firebase
import { storage } from '../../../lib/firebase';

// Components
import Dropzone from 'react-dropzone';
import TimePicker from 'react-time-picker';

// Constants
import {
	PLANS
} from '../../../const';

const styles = () =>
	({
		root : {
			width           : '100%',
			height          : 'calc(100% - 3rem)',
			position        : 'relative',
			display         : 'flex',
			flexDirection   : 'column',
			borderRadius    : '20px',
			backgroundColor : '#e5e5e2'
		},
		wrapper : {
			width           : '100%',
			backgroundColor : 'white',
			boxShadow       : '3px 3px 10px #aaa',
			padding         : '0.5% 1%'
		},
		group : {
			marginBottom : '2%'
		},
		pageTitle : {
			color      : 'var(--text-color)',
			fontSize   : '1.2rem',
			lineHeight : '1.4rem'
		},
		top : {
			display       : 'flex',
			flexDirection : 'row'
		},
		topLeft : {
			width : '50%'
		},
		topRight : {
			width : '50%'
		},
		topData : {
			height     : '2rem',
			display    : 'flex',
			margin     : '4% 0',
			alignItems : 'center',
			lineHeight : 1
		},
		inputLabel : {
			width         : '20%',
			fontSize      : '0.8rem',
			// whiteSpace    : 'nowrap',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-end'
		},
		label : {
			color        : 'var(--text-color)',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		labelSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.8rem',
			overflowWrap : 'break-word'
		},
		subLabel : {
			color        : 'var(--text-color)',
			fontSize     : '0.7rem',
			lineHeight   : '0.8rem',
			overflowWrap : 'break-word'
		},
		subLabelSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.6rem',
			lineHeight   : '0.7rem',
			overflowWrap : 'break-word'
		},
		subLabelExSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.5rem',
			lineHeight   : '0.6rem',
			overflowWrap : 'break-word'
		},
		inputValue : {
			width    : '80%',
			fontSize : '0.8rem',
			padding  : '0 3%'

		},
		inputValueFlex : {
			width          : '80%',
			fontSize       : '0.8rem',
			padding        : '0 3%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '20%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#F6F6F6'
			}
		},
		fullWidth : {
			width : '100%'
		},
		inputSwitch : {
			marginLeft : '5%'
		},
		inputList : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '12rem'
		},
		inputListSmall : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 0.3rem',
			color        : 'var(--text-color)',
			minWidth     : '4rem'
		},
		checkBox : {
			color                   : 'var(--text-color)',
			fontSize                : '0.8rem',
			'& .MuiTypography-root' : {
				fontSize   : '0.8rem',
				fontWeight : 300
			}
		},
		guestInputColSelect : {
			display     : 'block',
			marginRight : '1rem'
		},
		guestInputColInput : {
			display : 'block',
			width   : '100%'
		},
		imageGroup : {
			width   : '100%',
			display : 'flex'
		},
		imageLeft : {
			width       : '20%',
			marginRight : '2%'
		},
		imageRight : {
			width : '78%'
		},
		imageNameGroup : {
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center'
		},
		imageIcon : {
			color       : 'var(--text-color)',
			marginRight : '0.5rem'
		},
		imageBorder : {
			borderBottom : '1px solid var(--text-color)',
			padding      : '0 0.5rem'
		},
		imageName : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			whiteSpace : 'nowrap'
		},
		imageBox : {
			width          : '100%',
			height         : '10rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			padding        : '5% 3%',
			'&> img'       : {
				objectFit : 'contain'
			}
		},
		image : {
			width     : 'auto',
			height    : 'auto',
			display   : 'block',
			maxWidth  : '100%',
			maxHeight : '100%'
		},
		screenSetting : {
			display       : 'flex',
			flexDirection : 'row'
		},
		uptimeSetting : {
			width          : '40%',
			display        : 'flex',
			justifyContent : 'center',
			marginTop      : '1%'
		},
		nationalHolidaySetting : {
			width     : '11%',
			marginTop : '1%'
		},
		fieldset : {
			width          : '95%',
			height         : '11rem',
			borderColor    : 'var(--text-color)',
			borderRadius   : '0.3rem',
			borderWidth    : '1px',
			borderStyle    : 'solid',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'center'
		},
		legend : {
			marginLeft : '4%',
			fontSize   : '0.9rem',
			color      : 'var(--text-color)',
			padding    : '0 3%'
		},
		day : {
			display                    : 'flex',
			flexDirection              : 'row',
			justifyContent             : 'center',
			padding                    : '2% 0',
			cursor                     : 'pointer',
			'& > div:not(:last-child)' : {
				marginRight : '3%'
			}
		},
		active : {
			width          : '1.8rem',
			height         : '1.8rem',
			background     : 'var(--text-color)',
			border         : '1px solid var(--text-color)',
			borderRadius   : '1.8rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			color          : 'white',
			fontSize       : '0.9rem'
		},
		inActive : {
			width          : '1.8rem',
			height         : '1.8rem',
			background     : 'white',
			border         : '1px solid var(--text-color)',
			borderRadius   : '1.8rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			color          : 'var(--text-color)',
			fontSize       : '0.9rem'
		},
		nationalHoliday : {
			marginLeft : '0.5rem'
		},
		timeGroup : {
			display        : 'flex',
			flexDirection  : 'row',
			justifyContent : 'center',
			color          : 'var(--text-color)',
			fontSize       : '1rem',
			padding        : '2% 0'
		},
		time : {
			border         : 'none',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			fontSize       : '0.8rem',
			textAlign      : 'center',
			'& > div'      : {
				borderRadius : 5
			}
		},
		nationalHolidayInput :
		{
			padding : '2% 0',
			display : 'flex'
		},
		nationalHolidayInputTitle :
		{
			marginTop   : '3px',
			marginRight : '2rem',
			fontSize    : '0.9rem'
		},
		nationalHolidaySelect :
		{
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 0.3rem',
			color        : 'var(--text-color)',
			fontSize     : '0.8rem',
			minWidth     : '8rem'
		},
		buttonSetting : {
			width                : '15%',
			display              : 'flex',
			flexDirection        : 'column',
			alignItems           : 'center',
			marginTop            : '0%',
			'& > div:last-child' : {
				padding : '8% 0'
			}
		},
		buttonSettingList : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '3rem'
		},
		screen : {
			width : '27%'
		},
		actionGroup : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end',
			paddingBottom  : '1rem'
		},
		inputBtn : {
			marginLeft      : '4%',
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.3rem',
			padding         : '0.1rem 2rem',
			fontSize        : '0.8rem',
			color           : 'var(--text-color)'
		},
		errMsg :
		{
			color        : 'red',
			fontSize     : '0.9rem',
			lineHeight   : '0.9rem',
			marginBottom : '3px'
		},
		errorBox :
		{
			marginTop : '8px'
		},
		buttonColorInputArea :
		{
			width : '25%'
		},
		buttonTextInputArea :
		{
			width : '35%'
		},
		gradientInputArea :
		{
			width : '42%'
		},
		settingLineStart : {
			display        : 'flex',
			justifyContent : 'start'
		},
		waitingScreenSettingLine :
		{
			marginTop : '10px',
			display   : 'flex'
		},
		buttonPosTitle :
		{
			marginTop : '5px'
		},
		buttonPosInput :
		{
			marginRight : '5px'
		},
		inputSpaceRight :
		{
			marginRight : '30px'
		},
		inputFieldBtnPos :
		{
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '80px',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#EEE'
			}
		}
	});

const theme = createTheme({
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiSelect : {
			select : {
				'&:focus' : {
					borderRadius : '0.4rem'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const AdminUserEditView = ({
	classes,
	saveCb,
	closeCb,
	deleteCb,
	user,
	admin,
	errors,
	setErrors,
	setRoomsNum
}) =>
{
	const [ userNumber, setUserNumber ] = useState('');
	const [ userName, setUserName ] = useState('');
	const [ userEmail, setUserEmail ] = useState('');
	const [ userPassword, setUserPassword ] = useState('');
	const [ userAdminPassword, setUserAdminPassword ] = useState('');
	const [ userServiceName, setUserServiceName ] = useState('');
	const [ userSubDomain, setUserSubDomain ] = useState('');
	const [ userStatus, setUserStatus ] = useState(0);
	const [ userRoomNum, setUserRoomNum ] = useState(0);
	const [ userTodoCheck, setUserTodoCheck ] = useState(false);
	const [ userCallCheck, setUserCallCheck ] = useState(false);
	const [ userDirectModeCheck, setUserDirectModeCheck ] = useState(false);
	const [ userPlan, setUserPlan ] = useState(0);
	const [ userRole, setUserRole ] = useState('common');
	const [ userBtnPositionObj, setUserBtnPositionObj ] = useState({
		PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
		SP : { top: '49', bottom: '', verticCenter: false }
	});

	// Logo File
	const [ uploadLogoFile, setUploadLogoFile ] = useState();
	const [ userLogoUrl, setUserLogoUrl ] = useState('');

	// BG File for PC
	const [ uploadBgPCFile, setUploadBgPCFile ] = useState();
	const [ userBgPCUrl, setUserBgPCUrl ] = useState('');

	// BG File for SP
	const [ uploadBgSPFile, setUploadBgSPFile ] = useState();
	const [ userBgSPUrl, setUserBgSPUrl ] = useState('');

	// OPEN/CLOSE TIME
	const [ userOpenTime, setUserOpenTime ] = useState('10:00');
	const [ userCloseTime, setUserCloseTime ] = useState('18:00');

	// Holiday
	const [ userHoliday, setUserHoliday ] = useState([ 0, 0, 0, 0, 0, 0, 0, 0 ]);

	const [ userNationalHoliday, setUserNationalHoliday ] = useState(0);

	// GuestInput
	const [ guestInputField1, setGuestInputField1 ] = useState(
		{
			type : 'other',
			key  : ''
		});
	const [ guestInputField2, setGuestInputField2 ] = useState(
		{
			type : 'other',
			key  : ''
		});

	// Tell
	const [ userTell, setUserTell ] = useState('');

	// button
	const [ buttonColor, setButtonColor ] = useState('F15A24');
	const [ buttonText, setButtonText ] = useState('オンライン相談受付');

	// background
	const [ bgPCGradient, setBgPCGradient ] = useState('#EEEEEE, #EEEEEE');
	const [ bgSPGradient, setBgSPGradient ] = useState('#EEEEEE, #EEEEEE');

	// buttonSizeSetting
	const [ btnPosDirectionSelector, setBtnPosDirectionSelector ] = useState({ verticalPC: 'top', horizontalPC: 'left', verticalSP: 'top' });

	useEffect(() =>
	{
		if (user)
		{
			setUserNumber(user.number ? user.number : -1);
			setUserName(user.name ? user.name : '');
			setUserEmail(user.email ? user.email : '');
			setUserPassword(user.password ? user.password : '');
			setUserAdminPassword('非表示');
			setUserRoomNum(user.roomNum ? user.roomNum : 0);
			setRoomsNum(user.roomNum ? user.roomNum : 0);
			setUserServiceName(user.serviceName ? user.serviceName : '');
			setUserSubDomain(user.subdomain ? user.subdomain : '');
			setUserStatus(user.status ? user.status : 0);
			setUserPlan(user.plan ? user.plan : 0);
			setUserRole(user.role ? user.role : 'common');
			setUserTodoCheck(user.optionTodo ? user.optionTodo : false);
			setUserCallCheck(user.optionCall ? user.optionCall : false);
			setUserDirectModeCheck(user.directMode ? user.directMode : false);
			setUserLogoUrl(user.logoUrl ? user.logoUrl : '');
			setUserBgPCUrl(user.bgPCUrl ? user.bgPCUrl : '');
			setUserBgSPUrl(user.bgSPUrl ? user.bgSPUrl : '');
			setUserOpenTime(user.openTime ? user.openTime : '10:00');
			setUserCloseTime(user.closeTime ? user.closeTime : '18:00');
			setUserHoliday(user.holiday ? user.holiday : [ 0, 0, 0, 0, 0, 0, 0, 0 ]);
			setUserNationalHoliday(user.nationalHoliday ? user.nationalHoliday : 0);
			setUserTell(user.tell ? user.tell : '');
			setButtonColor(user.buttonColor ? user.buttonColor : 'F15A24');
			setButtonText(user.buttonText ? user.buttonText : 'オンライン相談受付');
			setBgPCGradient(user.bgPCGradient ? user.bgPCGradient : '#EEEEEE, #EEEEEE');
			setBgSPGradient(user.bgSPGradient ? user.bgSPGradient : '#EEEEEE, #EEEEEE');

			setGuestInputField1(user.guestInputField1 ? user.guestInputField1 : { type: 'other', key: '' });
			setGuestInputField2(user.guestInputField2 ? user.guestInputField2 : { type: 'other', key: '' });

			setUserBtnPositionObj(
				user.btnPosition && user.btnPosition.PC && user.btnPosition.SP
					? user.btnPosition
					: {
						PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
						SP : { top: '49', bottom: '', verticCenter: false }
					});
			// set initial value of button position direction selector, from db data
			setBtnPosDirectionSelector(() =>
			{
				let verticalPCVal = 'top';

				let horizontalPCVal = 'left';

				let verticalSPVal = 'top';

				if (user.btnPosition && user.btnPosition.PC && user.btnPosition.SP)
				{
					if (user.btnPosition.PC.bottom)
					{
						verticalPCVal = 'bottom';
					}
					else if (user.btnPosition.PC.verticCenter)
					{
						verticalPCVal = 'center';
					}

					if (user.btnPosition.PC.right)
					{
						horizontalPCVal = 'right';
					}
					else if (user.btnPosition.PC.horizCenter)
					{
						horizontalPCVal = 'center';
					}

					if (user.btnPosition.SP.bottom)
					{
						verticalSPVal = 'bottom';
					}
					else if (user.btnPosition.SP.verticCenter)
					{
						verticalSPVal = 'center';
					}
				}

				return {
					verticalPC   : verticalPCVal,
					horizontalPC : horizontalPCVal,
					verticalSP   : verticalSPVal
				};
			});
		}
		else
		{
			setUserNumber('');
			setUserName('');
			setUserEmail('');
			setUserPassword('');
			setUserAdminPassword('');
			setUserRoomNum(0);
			setUserServiceName('');
			setUserSubDomain('');
			setUserStatus(0);
			setUserPlan(0);
			setUserRole('common');
			setUserTodoCheck(false);
			setUserCallCheck(false);
			setUserDirectModeCheck(false);
			setUserLogoUrl('');
			setUserBgPCUrl('');
			setUserBgSPUrl('');
			setUserOpenTime('10:00');
			setUserCloseTime('18:00');
			setUserHoliday([ 0, 0, 0, 0, 0, 0, 0, 0 ]);
			setUserNationalHoliday(0);
			setGuestInputField1({ type: 'other', key: '' });
			setGuestInputField2({ type: 'other', key: '' });
			setUserTell('');
			setButtonColor('F15A24');
			setButtonText('オンライン相談受付');
			setBgPCGradient('#EEEEEE, #EEEEEE');
			setBgSPGradient('#EEEEEE, #EEEEEE');
			setUserBtnPositionObj({
				PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
				SP : { top: '49', bottom: '', verticCenter: false }
			});
			setBtnPosDirectionSelector({ verticalPC: 'top', horizontalPC: 'left', verticalSP: 'top' });
		}
	}, [ user ]);

	const handleChangeUserNumber = (e) =>
	{
		setUserNumber(e.target.value);
	};

	const handleChangeUserName = (e) =>
	{
		setUserName(e.target.value);
	};

	const handleChangeUserEmail = (e) =>
	{
		setUserEmail(e.target.value);
	};

	const handleChangeUserTell = (e) =>
	{
		setUserTell(e.target.value);
	};

	const handleChangeUserPassword = (e) =>
	{
		setUserPassword(e.target.value);
	};

	const handleChangeUserAdminPassword = (e) =>
	{
		setUserAdminPassword(e.target.value);
	};

	const handleChangeUserRoomNum = (e) =>
	{
		setUserRoomNum(e.target.value);
	};

	const handleChangeUserPlan = (e) =>
	{
		setUserPlan(e.target.value);
	};

	const handleChangeUserServiceName = (e) =>
	{
		setUserServiceName(e.target.value);
	};

	const handleChangeUserSubDomain = (e) =>
	{
		setUserSubDomain(e.target.value);
	};

	const handleChangeRole = (e) =>
	{
		setUserRole(e.target.value);
	};

	const handleChangeUserTodoCheck = (e) =>
	{
		setUserTodoCheck(e.target.checked);
	};

	const handleChangeUserCallCheck = (e) =>
	{
		setUserCallCheck(e.target.checked);
	};

	const handleChangeUserDirectModeCheck = (e) =>
	{
		setUserDirectModeCheck(e.target.checked);
	};

	const handleChangeHolidayCallback = (day) =>
	{

		if (userHoliday[day] === 1)
		{
			userHoliday[day] = 0;
		}
		else
		{
			userHoliday[day] = 1;
		}
		setUserHoliday([ ...userHoliday ]);
	};

	const handleChangeNationalHolidayCallback = (e) =>
	{
		setUserNationalHoliday(Number(e.target.value));
	};

	const handleChangeGuestInputField = (e, num) =>
	{

		if (num === 1)
		{
			setGuestInputField1({
				type : 'other',
				key  : e.target.value
			});
		}
		else if (num === 2)
		{
			setGuestInputField2({
				type : 'other',
				key  : e.target.value
			});
		}

	};

	const handleChangeUserBtnPositionObj = (screen, prop, value) =>
	{
		setUserBtnPositionObj((state) =>
		{
			const updatedState = { ...state };

			updatedState[screen][prop] = value;

			return {
				...updatedState
			};

		});
	};

	const onDropLogo = useCallback((acceptedFiles) =>
	{
		console.log('onDropLogo', acceptedFiles); // eslint-disable-line no-console

		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setUserLogoUrl(src);
			setUploadLogoFile(acceptedFiles[0]);
		}

	}, []);

	const onDropBgPC = useCallback((acceptedFiles) =>
	{

		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setUserBgPCUrl(src);
			setUploadBgPCFile(acceptedFiles[0]);
		}

	}, []);

	const onDropBgSP = useCallback((acceptedFiles) =>
	{

		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setUserBgSPUrl(src);
			setUploadBgSPFile(acceptedFiles[0]);
		}

	}, []);

	const uploadLogo = async () =>
	{
		let url = userLogoUrl ? userLogoUrl : '';

		if (uploadLogoFile && uploadLogoFile.name)
		{
			const storageref = storage.ref(`/images/${userSubDomain}/logo_${uploadLogoFile.name}`);
			const snapshot = await storageref.put(uploadLogoFile);

			url = await snapshot.ref.getDownloadURL();
			setUserLogoUrl(url);
		}

		return url;
	};

	const uploadBgPC = async () =>
	{
		let url = userBgPCUrl ? userBgPCUrl : '';

		if (uploadBgPCFile && uploadBgPCFile.name)
		{
			const storageref = storage.ref(`/images/${userSubDomain}/logo_${uploadBgPCFile.name}`);
			const snapshot = await storageref.put(uploadBgPCFile);

			url = await snapshot.ref.getDownloadURL();
			setUserBgPCUrl(url);
		}

		return url;
	};

	const uploadBgSP = async () =>
	{
		let url = userBgSPUrl ? userBgSPUrl : '';

		if (uploadBgSPFile && uploadBgSPFile.name)
		{
			const storageref = storage.ref(`/images/${userSubDomain}/logo_${uploadBgSPFile.name}`);
			const snapshot = await storageref.put(uploadBgSPFile);

			url = await snapshot.ref.getDownloadURL();
			setUserBgSPUrl(url);
		}

		return url;
	};

	const handleChangeButtonColor = (value) =>
	{
		setButtonColor(`${ value.replace(/[^0-9a-fA-F]/, '')}`);
	};

	const handleChangeGuestInput1Select = (e) =>
	{

		if (e.target.value === 'other')
		{
			setGuestInputField1({
				type : 'other',
				key  : ''

			});
		}
		else if (e.target.value === 'none')
		{
			setGuestInputField1({
				type : e.target.value,
				key  : ''
			});
		}
		else if (e.target.value === 'email')
		{
			setGuestInputField1({
				type : e.target.value,
				key  : 'Eメール'

			});
		}
		else if (e.target.value === 'phone')
		{
			setGuestInputField1({
				type : e.target.value,
				key  : '電話番号'

			});
		}
	};

	const handleChangeGuestInput2Select = (e) =>
	{

		if (e.target.value === 'other')
		{
			setGuestInputField2({
				type : 'other',
				key  : ''
			});
		}
		else if (e.target.value === 'none')
		{
			setGuestInputField2({
				type : e.target.value,
				key  : ''
			});
		}
		else if (e.target.value === 'email')
		{
			setGuestInputField2({
				type : e.target.value,
				key  : 'Eメール'
			});
		}
		else if (e.target.value === 'phone')
		{
			setGuestInputField2({
				type : e.target.value,
				key  : '電話番号'
			});
		}
	};

	const handleChangeBtnPosDirection = (prop, val) =>
	{

		// set value
		setBtnPosDirectionSelector((state) =>
		{
			const stateToUpdate = { ...state };

			stateToUpdate[prop] = val;

			return { ...stateToUpdate };
		});

		if (val === 'center')
		{
			if (prop==='verticalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.top = '';
					stateToUpdate.PC.bottom = '';
					stateToUpdate.PC.verticCenter = true;

					return { ...stateToUpdate };
				});
			}
			else if (prop==='horizontalPC')
			{
				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.left = '';
					stateToUpdate.PC.right = '';
					stateToUpdate.PC.horizCenter = true;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.top = '';
					stateToUpdate.SP.bottom = '';
					stateToUpdate.SP.verticCenter = true;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'top')
		{
			if (prop==='verticalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.top = '50';
					stateToUpdate.PC.bottom = '';
					stateToUpdate.PC.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.top = '50';
					stateToUpdate.SP.bottom = '';
					stateToUpdate.SP.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'bottom')
		{
			if (prop==='verticalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.bottom = '50';
					stateToUpdate.PC.top = '';
					stateToUpdate.PC.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.bottom = '50';
					stateToUpdate.SP.top = '';
					stateToUpdate.SP.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'left')
		{
			if (prop==='horizontalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.left = '50';
					stateToUpdate.PC.right = '';
					stateToUpdate.PC.horizCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'right')
		{
			if (prop==='horizontalPC')
			{

				setUserBtnPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.right = '50';
					stateToUpdate.PC.left = '';
					stateToUpdate.PC.horizCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
	};

	const handleSave = async () =>
	{
		if (saveCb)
		{
			const logUrl = await uploadLogo();
			const bgPCUrl = await uploadBgPC();
			const bgSPUrl = await uploadBgSP();

			// edit
			if (user)
			{
				// don't change email, subdomain, password, adminPassword
				saveCb({
					id               : user.id,
					number           : userNumber,
					name             : userName,
					tell             : userTell,
					roomNum          : userRoomNum,
					serviceName      : userServiceName,
					subdomain        : user.subdomain,
					status           : userStatus,
					plan             : userPlan,
					optionTodo       : userTodoCheck,
					optionCall       : userCallCheck,
					directMode       : userDirectModeCheck,
					logoUrl          : logUrl,
					bgPCUrl          : bgPCUrl,
					bgSPUrl          : bgSPUrl,
					btnPosition      : userBtnPositionObj,
					holiday          : userHoliday,
					nationalHoliday  : userNationalHoliday,
					guestInputField1 : guestInputField1,
					guestInputField2 : guestInputField2,
					openTime         : userOpenTime,
					closeTime        : userCloseTime,
					buttonColor      : buttonColor,
					buttonText       : buttonText,
					bgPCGradient     : bgPCGradient,
					bgSPGradient     : bgSPGradient,
					role             : userRole,
					remarks          : ''
				});
			}
			// 新規
			else
			{
				saveCb({
					id               : -1,
					number           : userNumber,
					name             : userName,
					email            : userEmail,
					tell             : userTell,
					password         : userPassword,
					adminPassword    : userAdminPassword,
					roomNum          : userRoomNum,
					serviceName      : userServiceName,
					subdomain        : userSubDomain,
					status           : userStatus,
					plan             : userPlan,
					optionTodo       : userTodoCheck,
					optionCall       : userCallCheck,
					directMode       : userDirectModeCheck,
					logoUrl          : logUrl,
					bgPCUrl          : bgPCUrl,
					bgSPUrl          : bgSPUrl,
					btnPosition      : userBtnPositionObj,
					holiday          : userHoliday,
					nationalHoliday  : userNationalHoliday,
					guestInputField1 : guestInputField1,
					guestInputField2 : guestInputField2,
					openTime         : userOpenTime,
					closeTime        : userCloseTime,
					buttonColor      : buttonColor,
					buttonText       : buttonText,
					bgPCGradient     : bgPCGradient,
					bgSPGradient     : bgSPGradient,
					role             : userRole,
					remarks          : ''
				});
			}
		}
	};

	const handleDelete = () =>
	{
		if (deleteCb)
		{
			deleteCb({
				id : user.id
			});
		}
	};

	const handleCancel = () =>
	{
		if (closeCb)
		{
			closeCb();
			setErrors({
				show   : false,
				errors : []
			});
		}
	};

	const week = [ '月', '火', '水', '木', '金', '土', '日' ];

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				<Box className={`${classes.wrapper} ${classes.group}`}>
					<Typography className={classes.pageTitle}>
						アカウント登録情報
					</Typography>
					{errors.show === true &&
					<Box className={classes.errorBox}>
						{errors.errors.map((err, index) =>
							(<Typography className={classes.errMsg} key={index}>
								・{err}
							</Typography>)
						)}
					</Box>

					}
					<Box className={classes.top}>
						<Box className={classes.topLeft}>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>顧客 No.</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userNumber}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserNumber}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>契約社名</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userName}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserName}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.labelSmall}>オペレータパスワード</Typography>
									<Typography className={classes.subLabelSmall}>(半角英数字8文字以上)</Typography>
								</Box>
								<Box className={classes.inputValue}>
									{	user ?
										<input
											value={''}
											className={`${classes.inputField} ${classes.fullWidth}`}
											type='text'
											disabled
										/>
										:
										<input
											value={userPassword}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={handleChangeUserPassword}
											type='text'
										/>
									}
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>管理者 ID</Typography>
									<Typography className={classes.subLabel}>(メールアドレス)</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userEmail}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserEmail}
										type='text'
										disabled={user}
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.labelSmall}>SMS通知用電話番号</Typography>
									<Typography className={classes.subLabel}>( ハイフンなし )</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userTell}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserTell}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.labelSmall}>管理者パスワード</Typography>
									<Typography className={classes.subLabelSmall}>
										(半角英数字8文字以上)
									</Typography>
									<Typography className={classes.subLabelSmall}>
										(英大文字を含む)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userAdminPassword}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserAdminPassword}
										type='text'
										disabled={user}
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.labelSmall}>ゲスト入力欄1</Typography>
								</Box>
								<Box className={classes.inputValueFlex}>
									<Box className={classes.guestInputColSelect}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={guestInputField1.type}
											onChange={handleChangeGuestInput1Select}
										>
											<MenuItem value={'phone'}>電話番号</MenuItem>
											<MenuItem value={'email'}>Eメール</MenuItem>
											<MenuItem value={'other'}>その他</MenuItem>
											<MenuItem value={'none'}>なし</MenuItem>
										</Select>
									</Box>

									<Box className={classes.guestInputColInput}>
										<input
											value={guestInputField1.key}
											disabled={guestInputField1.type !== 'other'}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeGuestInputField(e, 1)}
											type='text'
										/>
									</Box>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.labelSmall}>ゲスト入力欄2</Typography>
								</Box>
								<Box className={classes.inputValueFlex}>
									<Box className={classes.guestInputColSelect}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={guestInputField2.type}
											onChange={handleChangeGuestInput2Select}
										>
											<MenuItem value={'phone'}>電話番号</MenuItem>
											<MenuItem value={'email'}>Eメール</MenuItem>
											<MenuItem value={'other'}>その他</MenuItem>
											<MenuItem value={'none'}>なし</MenuItem>
										</Select>
									</Box>

									<Box className={classes.guestInputColInput}>
										<input
											value={guestInputField2.key}
											disabled={guestInputField2.type !== 'other'}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeGuestInputField(e, 2)}
											type='text'
										/>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box className={classes.topRight}>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ルーム数</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input value={userRoomNum} className={classes.inputField} onChange={handleChangeUserRoomNum} type='number' min='0'/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>プラン</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={userPlan}
										defaultValue={0}
										onChange={handleChangeUserPlan}
									>
										{ PLANS.map((plan) =>
										{
											return (
												<MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
											);
										})}
									</Select>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>サービス名</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userServiceName}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserServiceName}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>サブドメイン</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={userSubDomain}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeUserSubDomain}
										type='text'
										disabled={user}
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>管理権限</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={userRole}
										defaultValue={'common'}
										onChange={handleChangeRole}
										disabled={!admin.rights?.changeRole}
									>
										<MenuItem value={'admin'}>管理者</MenuItem>
										<MenuItem value={'superUser'}>編集者</MenuItem>
										<MenuItem value={'common'}>なし</MenuItem>
									</Select>
								</Box>
							</Box>
							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>オプション</Typography>
								</Box>
								<div className={classes.inputValue}>
									<FormGroup row>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={userTodoCheck}
													onChange={handleChangeUserTodoCheck}
												/>
											}
											label='TODO リスト'
										/>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={userCallCheck}
													onChange={handleChangeUserCallCheck}
												/>
											}
											label='着信リスト'
										/>
									</FormGroup>
								</div>
							</div>
							<div className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>通話開始</Typography>
								</Box>
								<div className={classes.inputValue}>
									<FormGroup row>
										<FormControlLabel
											className={classes.checkBox}
											control={
												<Checkbox
													checked={userDirectModeCheck}
													onChange={handleChangeUserDirectModeCheck}
												/>
											}
											label='ダイレクトモード'
										/>
									</FormGroup>
								</div>
							</div>
						</Box>
					</Box>
				</Box>
				<Box className={`${classes.imageGroup} ${classes.group}`}>
					<Box className={`${classes.imageLeft} ${classes.wrapper}`}>
						<Box height='1.4rem'/>
						<Box>
							<Box className={classes.imageNameGroup}>
								<ImageIcon className={classes.imageIcon}/>
								<Box className={classes.imageBorder}>
									<Typography className={classes.imageName}>
										ロゴファイル
									</Typography>
									<Dropzone
										onDrop={onDropLogo}
										className={classes.imageName}
										accept={{
											'image/png'  : [],
											'image/jpeg' : [],
											'image/gif'  : [],
											'image/jpg'  : []
										}}
									>
										{ ({ getRootProps, getInputProps }) => (
											<section>
												<div {...getRootProps()}>
													<input {...getInputProps()} />
													{uploadLogoFile ?
														<Typography>{uploadLogoFile.name}</Typography>
														:
														<Typography>ドラッグ or クリック</Typography>
													}
												</div>
											</section>
										)}
									</Dropzone>
								</Box>
							</Box>
							<Box className={classes.imageBox}>
								{ userLogoUrl &&
								<img
									alt='logo'
									src={userLogoUrl}
									height='100%'
									width='100%'
								/>
								}
							</Box>
						</Box>
					</Box>
					<Box className={`${classes.imageRight} ${classes.wrapper}`}>
						<Typography className={classes.pageTitle}>
							待受画面設定
						</Typography>
						<Box className={classes.screenSetting}>
							<Box className={classes.uptimeSetting}>
								<fieldset className={classes.fieldset}>
									<legend className={classes.legend}>システム稼働時間</legend>
									<Box className={classes.day}>
										{ week.map((day, index) =>
										{
											return (
												<Box key={index}>
													<Box
														className={userHoliday[index] === 1 ?
															classes.active : classes.inActive}
														onClick={() => handleChangeHolidayCallback(index)}
													>
														{day}
													</Box>
												</Box>
											);
										})}
									</Box>
									<Box className={classes.timeGroup}>
										<TimePicker
											className={classes.time}
											onChange={setUserOpenTime}
											disableClock
											value={userOpenTime}
										/>
										<Box ml={1} mr={1}>～</Box>
										<TimePicker
											className={classes.time}
											onChange={setUserCloseTime}
											disableClock
											value={userCloseTime}
										/>
									</Box>

									<Box className={classes.nationalHolidayInput}>
										<Typography className={classes.nationalHolidayInputTitle}>
											祝日
										</Typography>
										<Select
											classes={{ root: classes.nationalHolidaySelect }}
											value={userNationalHoliday}
											onChange={(e) => handleChangeNationalHolidayCallback(e)}
										>
											<MenuItem value={0}>休みなし</MenuItem>
											<MenuItem value={1}>祝日休み</MenuItem>
											<MenuItem value={2}>祝日休み+日曜振替</MenuItem>
											<MenuItem value={3}>祝日休み+土日振替</MenuItem>
										</Select>

									</Box>
								</fieldset>
							</Box>
							<Box className={classes.screen}>
								<Box className={classes.imageNameGroup}>
									<ImageIcon className={classes.imageIcon}/>
									<Box className={classes.imageBorder}>
										<Typography className={classes.imageName}>
											PC 待受け背景ファイル
										</Typography>
										<Dropzone onDrop={onDropBgPC} className={classes.imageName}>
											{ ({ getRootProps, getInputProps }) => (
												<section>
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														{uploadBgPCFile ?
															<Typography>{uploadBgPCFile.name}</Typography>
															:
															<Typography>ドラッグ or クリック</Typography>
														}
													</div>
												</section>
											)}
										</Dropzone>
									</Box>
								</Box>
								<Box className={classes.imageBox}>
									{ userBgPCUrl &&
									<img
										alt='logo'
										src={userBgPCUrl}
										height='100%'
										width='100%'
									/>
									}
								</Box>
							</Box>
							<Box className={classes.screen}>
								<Box className={classes.imageNameGroup}>
									<ImageIcon className={classes.imageIcon}/>
									<Box className={classes.imageBorder}>
										<Typography className={classes.imageName}>
											スマホ待受け背景ファイル
										</Typography>
										<Dropzone onDrop={onDropBgSP} className={classes.imageName}>
											{ ({ getRootProps, getInputProps }) => (
												<section>
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														{uploadBgSPFile ?
															<Typography>{uploadBgSPFile.name}</Typography>
															:
															<Typography>ドラッグ or クリック</Typography>
														}
													</div>
												</section>
											)}
										</Dropzone>
									</Box>
								</Box>
								<Box className={classes.imageBox}>
									{ userBgSPUrl &&
									<img
										alt='logo'
										src={userBgSPUrl}
										height='100%'
										width='100%'
									/>
									}
								</Box>
							</Box>
						</Box>

						<Box className={classes.waitingScreenSettingLine} >
							<Box className={`${classes.buttonColorInputArea} ${classes.inputSpaceRight}`}>
								<Typography >
									ボタンカラー
								</Typography>
								<Box>
									<input
										maxLength={7}
										value={`#${buttonColor}`}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => handleChangeButtonColor(e.target.value.substring(1))}
										type='text'
									/>
								</Box>

							</Box>

							<Box className={classes.buttonTextInputArea}>
								<Typography>
									ボタンテキスト
								</Typography>
								<Box>
									<input
										value={buttonText}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => setButtonText(e.target.value)}
										type='text'
									/>
								</Box>
							</Box>

						</Box>

						<Box className={classes.waitingScreenSettingLine} >
							<Box className={classes.inputSpaceRight}>
								<Typography className={classes.buttonPosTitle}>
									PC 縦ボタン位置
								</Typography>
								<Box className={classes.settingLineStart} >
									<Box className={classes.buttonPosInput}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={btnPosDirectionSelector.verticalPC}
											onChange={(e) => handleChangeBtnPosDirection('verticalPC', e.target.value)}
										>
											<MenuItem value={'top'}>上(%)</MenuItem>
											<MenuItem value={'bottom'}>下(%)</MenuItem>
											<MenuItem value={'center'}>中央揃え</MenuItem>
										</Select>
									</Box>

									<Box>
										<input
											value={userBtnPositionObj.PC.verticCenter ? '' : userBtnPositionObj['PC'][btnPosDirectionSelector.verticalPC]}
											disabled={btnPosDirectionSelector.verticalPC === 'center'}
											className={classes.inputFieldBtnPos}
											onChange={(e) => handleChangeUserBtnPositionObj(
												'PC',
												btnPosDirectionSelector.verticalPC,
												e.target.value
											)}
											type='number'
											min='0'
											max='100'
										/>
									</Box>
								</Box>
							</Box>
							<Box className={classes.inputSpaceRight}>
								<Typography className={classes.buttonPosTitle}>
									PC 横ボタン位置
								</Typography>
								<Box className={classes.settingLineStart} >
									<Box className={classes.buttonPosInput}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={btnPosDirectionSelector.horizontalPC}
											onChange={(e) => handleChangeBtnPosDirection('horizontalPC', e.target.value)}
										>
											<MenuItem value={'left'}>左(%)</MenuItem>
											<MenuItem value={'right'}>右(%)</MenuItem>
											<MenuItem value={'center'}>中央揃え</MenuItem>
										</Select>
									</Box>

									<Box>
										<input
											value={userBtnPositionObj.PC.horizCenter ? '' : userBtnPositionObj['PC'][btnPosDirectionSelector.horizontalPC]}
											disabled={btnPosDirectionSelector.horizontalPC === 'center'}
											className={classes.inputFieldBtnPos}
											onChange={(e) => handleChangeUserBtnPositionObj(
												'PC',
												btnPosDirectionSelector.horizontalPC,
												e.target.value
											)}
											type='number'
											min='0'
											max='100'
										/>
									</Box>
								</Box>

							</Box>
							<Box className={classes.inputSpaceRight}>
								<Typography className={classes.buttonPosTitle}>
									スマホ 縦ボタン位置
								</Typography>
								<Box className={classes.settingLineStart} >

									<Box className={classes.buttonPosInput}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={btnPosDirectionSelector.verticalSP}
											onChange={(e) => handleChangeBtnPosDirection('verticalSP', e.target.value)}
										>
											<MenuItem value={'top'}>上(%)</MenuItem>
											<MenuItem value={'bottom'}>下(%)</MenuItem>
											<MenuItem value={'center'}>中央揃え</MenuItem>
										</Select>
									</Box>

									<Box>
										<input
											value={userBtnPositionObj.SP.verticCenter ? '' : userBtnPositionObj['SP'][btnPosDirectionSelector.verticalSP]}
											disabled={btnPosDirectionSelector.verticalSP === 'center'}
											className={classes.inputFieldBtnPos}
											onChange={(e) => handleChangeUserBtnPositionObj(
												'SP',
												btnPosDirectionSelector.verticalSP,
												e.target.value
											)}
											type='number'
											min='0'
											max='100'
										/>
									</Box>
								</Box>
							</Box>
						</Box>

						<Box className={classes.waitingScreenSettingLine} >

							<Box className={`${classes.gradientInputArea} ${classes.inputSpaceRight}`}>
								<Typography>
									PC 背景グラディエーション
								</Typography>
								<Box>
									<input
										value={bgPCGradient}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => setBgPCGradient(e.target.value)}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.gradientInputArea}>
								<Typography>
									スマホ 背景グラディエーション
								</Typography>
								<Box>
									<input
										placeholder='#FFF, #FFF'
										value={bgSPGradient}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => setBgSPGradient(e.target.value)}
										type='text'
									/>
								</Box>
							</Box>

						</Box>
					</Box>
				</Box>
				<Box className={classes.actionGroup}>
					<Button
						onClick={handleSave}
						className={classes.inputBtn}
						variant='contained'
						disabled={
							(!user && !admin.rights?.createUser)
							|| (user && !admin.rights?.editUser)
						}
					>
						保存
					</Button>
					<Button
						onClick={handleDelete}
						className={classes.inputBtn}
						variant='contained'
						disabled={!user || !admin.rights?.deleteUser}
					>
						削除
					</Button>
					<Button onClick={handleCancel} className={classes.inputBtn} variant='contained'>
						キャンセル
					</Button>
				</Box>
			</Box>
		</MuiThemeProvider>
	);

};

AdminUserEditView.propTypes =
{
	classes     : PropTypes.object.isRequired,
	user        : PropTypes.object,
	admin       : PropTypes.object,
	saveCb      : PropTypes.func.isRequired,
	closeCb     : PropTypes.func.isRequired,
	deleteCb    : PropTypes.func.isRequired,
	errors      : PropTypes.object,
	setErrors   : PropTypes.func,
	setRoomsNum : PropTypes.func
};

export default withStyles(styles)(AdminUserEditView);
