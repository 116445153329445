export const setAdminUser = (user) =>
	({
		type    : 'SET_ADMIN_USER',
		payload : { user }
	});

export const setAdminUserIdAndRights = (user) =>
	({
		type    : 'SET_ADMIN_USERID_AND_RIGHTS',
		payload : { user }
	});

export const setAdminCalls = (calls) =>
	({
		type    : 'SET_ADMIN_CALLS',
		payload : { calls }
	});

export const setAdminLoginDialogOpen = (adminLoginDialogOpen) =>
	({
		type    : 'SET_ADMIN_LOGIN_DIALOG_OPEN',
		payload : { adminLoginDialogOpen }
	});

export const setAdminLoginInfoDialogOpen = (adminLoginInfoDialogOpen) =>
	({
		type    : 'SET_ADMIN_LOGIN_INFO_DIALOG_OPEN',
		payload : { adminLoginInfoDialogOpen }
	});

export const setAdminLoginConfirmDialogOpen = (adminLoginConfirmDialogOpen) =>
	({
		type    : 'SET_ADMIN_LOGIN_CONFIRM_DIALOG_OPEN',
		payload : { adminLoginConfirmDialogOpen }
	});

export const setAdminUserLoginDialogOpen = (adminUserLoginDialogOpen) =>
	({
		type    : 'SET_ADMIN_USER_LOGIN_DIALOG_OPEN',
		payload : { adminUserLoginDialogOpen }
	});

export const setChangePassSuccessDialogOpen = (changePassSuccessDialogOpen) =>
	({
		type    : 'SET_CHANGE_PASS_SUCCESS_DIALOG_OPEN',
		payload : { changePassSuccessDialogOpen }
	});

export const setAdminViewShown = (adminViewShown) =>
	({
		type    : 'SET_ADMIN_VIEW_SHOWN',
		payload : { adminViewShown }
	});

export const setAdminUsageDialogOpen = (adminUsageDialogOpen) =>
	({
		type    : 'SET_ADMIN_USAGE_DIALOG_OPEN',
		payload : { adminUsageDialogOpen }
	});

export const setAdminUsageDialogSubOpen = (adminUsageDialogSubOpen, date) =>
	({
		type    : 'SET_ADMIN_USAGE_DIALOG_SUB_OPEN',
		payload : { adminUsageDialogSubOpen, date }
	});

export const resetAdminView = () =>
	({
		type    : 'RESET_ADMIN_VIEW',
		payload : {}
	});
