// React
import React, { useEffect, useState, useCallback } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as adminActions from '../../../actions/adminActions';
import * as roomActions from '../../../actions/roomActions';

// Message
import { FormattedMessage } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// Firebase
import { auth } from '../../../lib/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const styles = (theme) =>
	({
		root : {
			display : 'flex',
			width   : '100%',
			height  : '100%'
		},
		dialogRoot : {
			pointerEvents : 'none'
		},
		dialogPaper : {
			padding                        : '1% 2%',
			width                          : '20vw',
			pointerEvents                  : 'auto',
			[theme.breakpoints.down('lg')] : {
				width : '30vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] : {
				width : '90vw'
			}
		},
		inputGroup : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		icon : {
			width        : 'auto',
			height       : '2rem',
			marginBottom : '0.8rem'
		},
		contentTitle : {
			color        : 'var(--text-color)',
			fontSize     : '0.7rem',
			marginBottom : '0.6rem'
		},
		input : {
			width        : '80%',
			border       : 'none',
			marginBottom : '1.5rem'
		},
		button : {
			backgroundColor : 'var(--submit-button-color)',
			marginTop       : '1rem',
			marginBottom    : '1rem',
			'&:hover'       : {
				backgroundColor : 'var(--hover-button-color)'
			}
		},
		linkText : {
			fontSize     : '0.6rem',
			color        : 'var(--text-color)',
			marginBottom : '2rem'
		},
		link : {
			color : 'var(--submit-button-color)'
		},
		errorMsg : {
			width     : '250px',
			height    : '1.2rem',
			color     : 'red',
			marginTop : '5px',
			fontSize  : '0.8rem',
			textAlign : 'center'
		}
	});

const theme = createTheme({
	overrides : {
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none',
				padding        : '1rem 0'
			}
		},
		MuiDialogActions : {
			root : {
				flexDirection  : 'column',
				justifyContent : 'center'
			},
			spacing : {
				'& > :not(:first-child)' : {
					marginLeft : '0'
				}
			}
		},
		MuiButton : {
			label : {
				marginLeft  : '0.6rem',
				marginRight : '0.6rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				padding         : '0.4rem 0.8rem',
				border          : 'none',
				borderRadius    : '0.5rem',
				backgroundColor : 'var(--text-color)',
				boxShadow       : '2px 2px 4px inset var(--text-color)',
				color           : 'white'
			},
			notchedOutline : {
				border : 'none'
			}
		}
	}
});

const AdminLoginDialog = ({
	classes,
	setAdminLoginDialogOpen,
	setAdminLoginInfoDialogOpen,
	setAdminViewShow
}) =>
{
	const [ userEmail, setUserEmail ] = useState('');
	const [ userAdminPassword, setUserAdminPassword ] = useState('');
	const [ admin ] = useAuthState(auth);
	const [ errorMsg, setErrorMsg ] = useState([ '', '' ]);

	const handleChangeUserEmail = (e) =>
	{
		setUserEmail(e.target.value);
	};

	const handleChangeUserAdminPassword = (e) =>
	{
		setUserAdminPassword(e.target.value);
		setErrorMsg([ '', '' ]);
	};

	const handleSubmit = useCallback(() =>
	{
		// eslint-disable-next-line no-console
		console.log('Login started userEmail:', userEmail, 'userAdminPasswrod:', userAdminPassword);
		auth.signInWithEmailAndPassword(userEmail, userAdminPassword).then(() =>
		{
			setErrorMsg([ '', '' ]);
		})
			.catch(() =>
			{
				setErrorMsg([ 'ログインに失敗しました。', 'IDとパスワードを確認してください' ]);
			});
	},
	[
		setErrorMsg,
		userEmail,
		userAdminPassword
	]);

	const moveToPasswordForgot = () =>
	{
		setAdminLoginDialogOpen(false);
		setAdminLoginInfoDialogOpen(true);
	};

	useEffect(() =>
	{
		let cleanedUp = false;

		if (admin)
		{
			if (!cleanedUp)
			{
				setAdminLoginDialogOpen(false);
				setAdminViewShow(true);
			}
		}

		const cleanup = () =>
		{
			cleanedUp = true;
		};

		return cleanup;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ admin, setAdminLoginDialogOpen, setAdminViewShow ]);

	const loginByKeydown = useCallback((e) =>
	{

		if (e.keyCode === 13)
		{
			handleSubmit();
		}
	}, [ handleSubmit ]);

	useEffect(() =>
	{
		document.addEventListener('keydown', loginByKeydown);

		return () =>
		{
			// Clean up the subscription
			document.removeEventListener('keydown', loginByKeydown);
		};
	}, [ loginByKeydown ]);

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				open
				onClose={() => setAdminLoginDialogOpen(false)}
				classes={{
					root  : classes.dialogRoot,
					paper : classes.dialogPaper
				}}
				BackdropProps={{
					style : {
						backgroundColor : 'transparent'
					}
				}}
			>
				<DialogTitle />
				<DialogContent>
					<Box className={classes.inputGroup}>
						<img
							src='images/user_setting_icon.png'
							className={classes.icon}
							alt=''
						/>
						<Typography className={classes.contentTitle}>
							管理者ID
						</Typography>
						<TextField
							placeholder='契約時に登録した管理者メールアドレス'
							autoFocus
							variant='outlined'
							classes={{
								root : classes.input
							}}
							inputProps={{
								classes : {
									input : classes.input
								},
								style : {
									textAlign : 'center',
									fontSize  : '0.75rem'
								}
							}}
							value={userEmail}
							onChange={handleChangeUserEmail}
						/>
					</Box>
					<Box className={classes.inputGroup}>
						<img
							src='images/pass_setting_icon.png'
							className={classes.icon}
							alt=''
						/>
						<Typography className={classes.contentTitle}>
							管理者パスワード
						</Typography>
						<TextField
							placeholder='管理者パスワード'
							variant='outlined'
							classes={{
								root : classes.input
							}}
							inputProps={{
								classes : {
									input : classes.input
								},
								style : {
									textAlign : 'center',
									fontSize  : '0.75rem'
								}
							}}
							value={userAdminPassword}
							onChange={handleChangeUserAdminPassword}
						/>

						{ (errorMsg[0] || errorMsg[1]) &&
							<Typography className={classes.errorMsg}>
								{errorMsg[0]}<br/>{errorMsg[1]}
							</Typography>
						}

					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						color='secondary'
						classes={{
							root : classes.button
						}}
						onClick={handleSubmit}
					>
						<FormattedMessage
							id='label.login'
							defaultMessage='Login'
						/>
					</Button>
					<Typography className={classes.linkText}>
						管理者ID・パスワードお忘れの方は
						<Link href='#' className={classes.link} underline='none' onClick={moveToPasswordForgot} >
							こちら
						</Link>
					</Typography>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

AdminLoginDialog.propTypes =
{
	classes                     : PropTypes.object.isRequired,
	setAdminLoginDialogOpen     : PropTypes.func.isRequired,
	setAdminLoginInfoDialogOpen : PropTypes.func.isRequired,
	setAdminViewShow            : PropTypes.func.isRequired,
	setIsLoading                : PropTypes.func.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setAdminLoginDialogOpen : (loginInfoDialogOpen) =>
		{
			dispatch(adminActions.setAdminLoginDialogOpen(loginInfoDialogOpen));
		},
		setAdminLoginInfoDialogOpen : (adminLoginInfoDialogOpen) =>
		{
			dispatch(adminActions.setAdminLoginInfoDialogOpen(adminLoginInfoDialogOpen));
		},
		setAdminViewShow : (adminViewShown) =>
		{
			dispatch(adminActions.setAdminViewShown(adminViewShown));
		},
		setIsLoading : (flag) =>
		{
			dispatch(roomActions.setIsLoading(flag));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(AdminLoginDialog)));
