// React
import React, { useState } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// firestore
import { firestore } from '../../../lib/firebase';

// confirmDialog
import ConfirmDialog from '../Common/ConfirmDialog';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

// dayjs
import dayjs from 'dayjs';

// downLoadDate
const today = new Date();
const todayValues = {
	year  : today.getFullYear(),
	month : today.getMonth() + 1,
	day   : today.getDate()
};

// years from service start
const yearsToToday = [];

for (let y=2022; y <= todayValues.year; y++)
{
	yearsToToday.push(y);
}

// create days array from end of month day
const createDaysAr = (endOfMonth) =>
{
	const daysAr = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
		15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28 ];

	for (let d=29; d <= endOfMonth; d++)
	{
		daysAr.push(d);
	}

	return daysAr;
};

// final day of this month
const initialEndOfMonth =
dayjs()
	.endOf('month')
	.get('date');

// days of this month
const initialDays = createDaysAr(initialEndOfMonth);

const styles = () =>
	({
		exportBtn : {
			fontSize     : '1rem',
			background   : 'white',
			color        : 'var(--text-color)',
			border       : '1px solid var(--text-color)',
			boxShadow    : 'none',
			marginBottom : '3px'
		},
		firstExportBtn : {
			marginLeft : '2rem'
		},
		secondExportBtn	: {
			marginLeft : '1rem'
		},
		dateSelectBox : {
			fontSize       : '1.5rem',
			marginBottom   : '1rem',
			color          : 'var(--text-color)',
			display        : 'flex',
			justifyContent : 'start',
			height         : '2rem'
		},
		dateSelectInfo : {
			display        : 'flex',
			justifyContent : 'start'
		},
		selectMonthDay : {
			marginLeft : '0.25rem'
		},
		separator : {
			marginLeft  : '0.4rem',
			marginRight : '0.4rem',
			lineHeight  : '1.75rem'
		},
		dateSelectTitle : {
			display       : 'block',
			fontSize      : '1.1rem',
			paddingBottom : '4px',
			color         : '#999898'
		},
		dateSelect : {
			color : '#888'
		}
	});

const theme = createTheme({
	overrides : {
		MuiOutlinedInput : {
			inputMarginDense : {
				paddingTop    : '5px',
				paddingBottom : '5px'
			}
		}
	}
});

const AdminDateSelectDownload = ({
	classes,
	selected,
	users
}) =>
{
	const [ selectedDate, setSelectedDate ] = useState({
		startYear        : todayValues.year,
		startMonth       : 1,
		startDay         : 1,
		endYear          : todayValues.year,
		endMonth         : todayValues.month,
		endDay     	     : todayValues.day,
		startMonthDaysAr : [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
			15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31 ],
		endMonthDaysAr : [ ...initialDays ]
	});

	const [ confirmDialogState, setConfirmDialogState ] = useState(
		{
			show    : false,
			title   : '',
			message : ''
		}
	);

	const closeConfirmDialog = () =>
	{
		setConfirmDialogState(
			{
				show    : false,
				title   : '',
				message : ''
			}
		);
	};

	const changeDownloadDate = (typeKey, termKey, value) =>
	{
		const newDateValues = {};

		// new value of start/end + Year/Month/Day
		newDateValues[`${typeKey}${termKey}`] = Number(value);

		// no change of days array
		if (termKey==='Day' || (termKey==='Year' && selectedDate.startMonth !== 2))
		{
			setSelectedDate((state) =>
			{
				return {
					...state,
					...newDateValues
				};
			});
		}
		// update array of days and startDay/endDay
		else
		{
			// string of first day of target month
			let dateStr;

			if (termKey === 'Month')
			{
				const yearVal = selectedDate[`${typeKey}Year`];

				dateStr = `${yearVal}-${value}-1`;
			}
			else
			{
				const monthVal = selectedDate[`${typeKey}Month`];

				dateStr = `${value}-${monthVal}-1`;
			}

			// final day of target month
			const endOfMonth =
			dayjs(dateStr, 'YYYY-M-D')
				.endOf('month')
				.get('date');

			// change startDay/endDay if it exceeds end of Month
			let dayVal = selectedDate[`${typeKey}Day`];

			if (dayVal > endOfMonth)
			{
				dayVal = endOfMonth;
			}

			// to update startDay/endDay
			newDateValues[`${typeKey}Day`] = dayVal;

			// to update startMonthDaysAr/endMonthDaysAr(array of days in target Month)
			newDateValues[`${typeKey}MonthDaysAr`] = createDaysAr(endOfMonth);

			setSelectedDate((state) =>
			{
				return {
					...state,
					...newDateValues
				};
			});
		}
	};

	//
	// fetch from fireStore and export csv 
	//
	const downloadFile = ({ data, fileName, fileType }) =>
	{
		const bom = new Uint8Array([ 0xEF, 0xBB, 0xBF ]);
		const blob = new Blob([ bom, data ], { type: fileType });
		const a = document.createElement('a');

		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);

		const clickEvent = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });

		a.dispatchEvent(clickEvent);
		a.remove();
	};

	const exportToCsv = (fileData) =>
	{

		const headers = [ '顧客No.,日付,通話時間(秒数）' ];

		const body = [];

		fileData.forEach((d) =>
		{
			const { number, date, duration } = d;

			body.push([ number, date, duration ].join(','));
		});

		downloadFile({
			data     : [ ...headers, ...body ].join('\n'),
			fileName : '2nddoor_calls.csv',
			fileType : 'text/csv'
		});
	};

	const handleFireStoreData = (data, allUsers) =>
	{

		const fetchedCalls = [];
		const csvData = [];

		data.forEach((doc) =>
		{
			fetchedCalls.push(doc.data());
		});

		if (fetchedCalls.length === 0)
		{
			setConfirmDialogState(
				{
					show    : true,
					title   : '通知',
					message : '該当期間のデータは見つかりませんでした'
				}
			);
		}
		else
		{

			// create user's id and number map to find user number faster
			const usersNumberMap = new Map();

			if (allUsers === true)
			{
				const payingUserPattern = /^R/;

				users.forEach((u) =>
				{
					if (payingUserPattern.test(u.number))
					{
						usersNumberMap.set(u.id, u.number);
					}
				});
			}
			else
			{
				users.forEach((u) =>
				{
					usersNumberMap.set(u.id, u.number);
				});
			}
			fetchedCalls.forEach((call) =>
			{

				const userNumber = usersNumberMap.get(call.uid);

				// uid exists in users collection
				if (userNumber)
				{
					csvData.push(
						{
							number   : userNumber,
							date     : dayjs(call.timestamp).format('YYYY/MM/DD'),
							duration : call.duration
						}
					);
				}
			});

			exportToCsv(csvData);
		}
	};

	const fetchCalls = (arg) =>
	{

		if (arg.allUsers !== true && (!selected || selected.length === 0))
		{
			setConfirmDialogState(
				{
					show    : true,
					title   : 'エラー',
					message : '顧客を選択してください'
				}
			);
		}
		else
		{
			const startDateStr = `${selectedDate.startYear}-${selectedDate.startMonth}-${selectedDate.startDay}`;

			const endDateStr = `${selectedDate.endYear}-${selectedDate.endMonth}-${selectedDate.endDay}`;

			const timeStampStartDate = dayjs(startDateStr, 'YYYY-M-D').valueOf();

			let timeStampEndDate = dayjs(endDateStr, 'YYYY-M-D').valueOf();

			if (timeStampEndDate < timeStampStartDate)
			{
				setConfirmDialogState(
					{
						show    : true,
						title   : 'エラー',
						message : '終了日より遅い開始日が指定されています'
					}
				);
			}
			else
			{
				timeStampEndDate += 86400000; // +1day

				if (arg.allUsers === true)
				{
					firestore.collection('calls')
						.orderBy('timestamp')
						.where('timestamp', '>=', timeStampStartDate)
						.where('timestamp', '<', timeStampEndDate)
						.get()
						.then((data) =>
						{
							handleFireStoreData(data, arg.allUsers);
						})
						.catch((e) =>
						{
							setConfirmDialogState(
								{
									show    : true,
									title   : 'エラー',
									message : 'サーバーエラーが発生しました'
								}
							);
							// eslint-disable-next-line no-console
							console.log('exportCSV error:', e);
						});
				}
				else
				{
					firestore.collection('calls')
						.orderBy('timestamp')
						.where('timestamp', '>=', timeStampStartDate)
						.where('timestamp', '<', timeStampEndDate)
						.where('uid', 'in', selected)
						.get()
						.then((data) =>
						{
							handleFireStoreData(data, arg.allUsers);
						})
						.catch(() =>
						{
							setConfirmDialogState(
								{
									show    : true,
									title   : 'エラー',
									message : 'サーバーエラーが発生しました'
								}
							);
						});
				}
			}
		}
	};

	return (
		<MuiThemeProvider theme={theme}>

			<ConfirmDialog
				show={confirmDialogState.show}
				title={confirmDialogState.title}
				message={confirmDialogState.message}
				accept={() => {}}
				cancel={closeConfirmDialog}
				closeButtonOnly
			/>

			<div>
				<Box className={classes.dateSelectInfo}>
					<Typography variant='subtitle1' className={classes.dateSelectTitle}>
						CSV出力 期間設定
					</Typography>

				</Box>
				<Box className={classes.dateSelectBox}>
					<FormControl variant='outlined' sx={{ m: 1, minWidth: 70 }} size='small'>
						<InputLabel id='start-month-select-label'>年</InputLabel>
						<Select
							labelId='start-month-select'
							id='start-month-select'
							value={selectedDate.startYear}
							onChange={(e) => changeDownloadDate('start', 'Year', e.target.value)}
							className={classes.dateSelect}
							label='年'
						>
							{
								yearsToToday.map((y) => (
									<MenuItem value={y} key={y}>{y}</MenuItem>
								))
							}
						</Select>
					</FormControl>

					<FormControl
						variant='outlined'
						sx={{ m: 1, minWidth: 50 }}
						size='small'
						className={classes.selectMonthDay}
					>
						<InputLabel id='start-month-select-label'>月</InputLabel>
						<Select
							labelId='start-month-select'
							id='start-month-select'
							value={selectedDate.startMonth}
							onChange={(e) => changeDownloadDate('start', 'Month', e.target.value)}
							className={classes.dateSelect}
							label='月'
						>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
							<MenuItem value={5}>5</MenuItem>
							<MenuItem value={6}>6</MenuItem>
							<MenuItem value={7}>7</MenuItem>
							<MenuItem value={8}>8</MenuItem>
							<MenuItem value={9}>9</MenuItem>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={11}>11</MenuItem>
							<MenuItem value={12}>12</MenuItem>
						</Select>
					</FormControl>

					<FormControl
						variant='outlined'
						sx={{ m: 1, minWidth: 50 }}
						size='small'
						className={classes.selectMonthDay}
					>
						<InputLabel id='start-month-select-label'>日</InputLabel>
						<Select
							labelId='start-day-select'
							id='start-day-select'
							value={selectedDate.startDay}
							onChange={(e) => changeDownloadDate('start', 'Day', e.target.value)}
							className={classes.dateSelect}
							label='日'
						>
							{
								selectedDate.startMonthDaysAr.map((d) => (
									<MenuItem value={d} key={d}>{d}</MenuItem>
								))
							}
						</Select>
					</FormControl>

					<Typography variant='h6' className={classes.separator}>
						~
					</Typography>

					<FormControl variant='outlined' sx={{ m: 1, minWidth: 70 }} size='small'>
						<InputLabel id='start-month-select-label'>年</InputLabel>
						<Select
							labelId='start-month-select'
							id='start-month-select'
							value={selectedDate.endYear}
							onChange={(e) => changeDownloadDate('end', 'Year', e.target.value)}
							className={classes.dateSelect}
							label='年'
						>
							{
								yearsToToday.map((y) => (
									<MenuItem value={y} key={y}>{y}</MenuItem>
								))
							}
						</Select>
					</FormControl>

					<FormControl
						variant='outlined'
						sx={{ m: 1, minWidth: 50 }}
						size='small'
						className={classes.selectMonthDay}
					>
						<InputLabel id='start-month-select-label'>月</InputLabel>
						<Select
							labelId='start-month-select'
							id='start-month-select'
							value={selectedDate.endMonth}
							onChange={(e) => changeDownloadDate('end', 'Month', e.target.value)}
							className={classes.dateSelect}
							label='月'
						>
							<MenuItem value={1}>1</MenuItem>
							<MenuItem value={2}>2</MenuItem>
							<MenuItem value={3}>3</MenuItem>
							<MenuItem value={4}>4</MenuItem>
							<MenuItem value={5}>5</MenuItem>
							<MenuItem value={6}>6</MenuItem>
							<MenuItem value={7}>7</MenuItem>
							<MenuItem value={8}>8</MenuItem>
							<MenuItem value={9}>9</MenuItem>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={11}>11</MenuItem>
							<MenuItem value={12}>12</MenuItem>
						</Select>
					</FormControl>

					<FormControl
						variant='outlined'
						sx={{ m: 1, minWidth: 50 }}
						size='small'
						className={classes.selectMonthDay}
					>
						<InputLabel id='start-month-select-label'>日</InputLabel>
						<Select
							labelId='start-day-select'
							id='start-day-select'
							value={selectedDate.endDay}
							onChange={(e) => changeDownloadDate('end', 'Day', e.target.value)}
							className={classes.dateSelect}
							label='日'
						>
							{
								selectedDate.endMonthDaysAr.map((d) => (
									<MenuItem value={d} key={d}>{d}</MenuItem>
								))
							}
						</Select>
					</FormControl>

					<Button
						variant='contained'
						className={`${classes.exportBtn} ${classes.firstExportBtn} `}
						onClick={() => fetchCalls({ allUsers: false })}
						disabled={selected?.length === 0}
					>
						選択出力
					</Button>

					<Button
						variant='contained'
						className={`${classes.exportBtn} ${classes.secondExportBtn} `}
						onClick={() => fetchCalls({ allUsers: true })}
					>
						全顧客出力
					</Button>
				</Box>
			</div>
		</MuiThemeProvider>

	);
};

AdminDateSelectDownload.propTypes =
{
	classes  : PropTypes.object.isRequired,
	selected : PropTypes.array.isRequired,
	users    : PropTypes.array.isRequired
};

export default withStyles(styles)(AdminDateSelectDownload);