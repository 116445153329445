const initialState =
{
	user                        : null,
	userId                      : null,
	userRights                  : {},
	calls                       : [],
	adminLoginDialogOpen        : true,
	adminLoginInfoDialogOpen    : false,
	adminLoginConfirmDialogOpen : false,
	adminUserLoginDialogOpen    : true,
	changePassSuccessDialogOpen : false,
	adminViewShown              : false,
	adminUsageDialogOpen        : false,
	adminUsageDialogSubOpen     : false,
	adminUsageDialogSubDate     : 0
};

const admin = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_ADMIN_USER':
		{
			const { user } = action.payload;

			return { ...state, user };
		}

		case 'SET_ADMIN_USERID_AND_RIGHTS':
		{
			const { user } = action.payload;

			return { ...state, userId: user.userId, rights: user.rights };
		}

		case 'SET_ADMIN_CALLS':
		{
			const { calls } = action.payload;

			return { ...state, calls };
		}

		case 'SET_ADMIN_LOGIN_DIALOG_OPEN':
		{
			const { adminLoginDialogOpen } = action.payload;

			return { ...state, adminLoginDialogOpen };
		}

		case 'SET_ADMIN_LOGIN_INFO_DIALOG_OPEN':
		{
			const { adminLoginInfoDialogOpen } = action.payload;

			return { ...state, adminLoginInfoDialogOpen };
		}

		case 'SET_ADMIN_LOGIN_CONFIRM_DIALOG_OPEN':
		{
			const { adminLoginConfirmDialogOpen } = action.payload;

			return { ...state, adminLoginConfirmDialogOpen };
		}

		case 'SET_ADMIN_USER_LOGIN_DIALOG_OPEN':
		{
			const { adminUserLoginDialogOpen } = action.payload;

			return { ...state, adminUserLoginDialogOpen };
		}

		case 'SET_CHANGE_PASS_SUCCESS_DIALOG_OPEN':
		{
			const { changePassSuccessDialogOpen } = action.payload;

			return { ...state, changePassSuccessDialogOpen };
		}

		case 'SET_ADMIN_VIEW_SHOWN':
		{
			const { adminViewShown } = action.payload;

			return { ...state, adminViewShown };
		}

		case 'SET_ADMIN_USAGE_DIALOG_OPEN':
		{
			const { adminUsageDialogOpen } = action.payload;

			return { ...state, adminUsageDialogOpen };
		}

		case 'SET_ADMIN_USAGE_DIALOG_SUB_OPEN':
		{
			const { adminUsageDialogSubOpen, date } = action.payload;

			return { ...state, adminUsageDialogSubOpen, adminUsageDialogSubDate: date };
		}

		case 'RESET_ADMIN_VIEW':
		{

			return { ...initialState };
		}

		default:
			return state;
	}
};

export default admin;
