// React
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
// Prop types
import PropTypes from 'prop-types';

// Redux
import { withRoomContext } from '../../../RoomContext';
import { connect } from 'react-redux';
import * as roomActions from '../../../actions/roomActions';
import * as settingsActions from '../../../actions/settingsActions';

// Components
import Button from '@material-ui/core/Button';
import NoticeJoinRoomDialog from './NoticeJoinRoomDialog';

// Mui
import { withStyles } from '@material-ui/core/styles';

// Firebase
import { firestore } from '../../../lib/firebase';

// Daysjs
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

dayjs.extend(weekday);

const breakPointAspectRatio = 1;

const styles = (theme) =>
	({
		root :
		{
			display              : 'flex',
			flexDirection        : 'column',
			justifyContent       : 'center',
			alignItems           : 'flex-start',
			width                : '100%',
			height               : '100%',
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat',
			position             : 'relative'
		},
		logo :
		{
			position                     : 'fixed',
			left                         : '5%',
			top                          : '5%',
			marginLeft                   : 0,
			[theme.breakpoints.up('sm')] :
			{
				display : 'none' // block
			},
			width       : '30vw',
			aspectRatio : 2.5,
			objectFit   : 'conatin',
			display     : 'none'
		},
		joinButton :
		{
			maxWidth     : '500px',
			borderRadius : '5px',
			boxShadow    : 'grey 10px 10px 10px',
			color        : 'white',
			fontSize     : '1.2rem',
			margin       : 'auto',
			visibility   : 'hidden', // hide first because ugly shape show up
			'&:hover'    :
			{
				filter : 'saturate(105%) brightness(105%)'
			}
		},
		joinButtonPC :
		{
			position  : 'absolute',
			maxHeight : '10%',
			maxWidth  : '30%'
		},
		joinButtonSP :
		{
			position  : 'absolute',
			width     : '80%',
			left      : '0',
			right     : '0',
			margin    : 'auto',
			maxHeight : '10%'
		},
		information :
		{
			fontSize        : '1rem',
			backgroundColor : 'rgba(255,255,255,0.8)',
			width           : '100%',
			display         : 'none', // 'flex',
			flexDirection   : 'column',
			alignItems      : 'center',
			justifyContent  : 'center',
			padding         : '5%'
		},
		imageContentPC :
		{
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100vw',
			maxHeight      : '100%'
		},
		imageContentSP :
		{
			position       : 'relative',
			objectPosition : '50% 50%',
			objectFit      : 'contain !important',
			maxWidth       : '100%',
			maxHeight      : '100vh'
		},
		container : {
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'center',
			width          : '100%',
			height         : '100%'
		},
		imageArea : {
			position : 'relative'
		}
	});

const GuestLobby = ({
	mediaPerms,
	classes,
	setNoticeJoinRoomDialogOpen
}) =>
{
	const history = useHistory();

	// Image URls
	const [ userLogoUrl, setUserLogoUrl ] = useState('');
	const [ userBgPCUrl, setUserBgPCUrl ] = useState('');
	const [ userBgSPUrl, setUserBgSPUrl ] = useState('');

	// OPEN/CLOSE TIME
	const [ userOpenTime, setUserOpenTime ] = useState('10:00');
	const [ userCloseTime, setUserCloseTime ] = useState('18:00');

	// TELL
	const [ userTell, setUserTell ] = useState('0120-111-1111');

	// Button Position
	const [ userBtnPositionObj, setUserBtnPositionObj ] = useState({
		PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
		SP : { top: '49', bottom: '', verticCenter: false }
	});

	// Holiday
	const [ userHoliday, setUserHoliday ] = useState([ 0, 0, 0, 0, 0, 0, 0, 0 ]);

	// nationalHolidays 
	const [ nationalHolidays, setNationalHolidays ] = useState([]);

	// button
	const [ buttonColor, setButtonColor ] = useState('F15A24');
	const [ buttonText, setButtonText ] = useState('オンライン相談受付');

	// background
	const [ bgPCGradient, setBgPCGradient ] = useState('#EEEEEE, #EEEEEE');
	const [ bgSPGradient, setBgSPGradient ] = useState('#EEEEEE, #EEEEEE');

	// directMode
	const [ directMode, setDirectMode ] = useState(false);

	// window size
	const [ aspectRatio, setAspectRatio ] = useState(window.innerWidth/window.innerHeight);

	const startButtonRef = useRef();
	const bgImgRef = useRef();
	const bgImgAreaRef = useRef();

	// Is OpenTime ?
	const isWorkTime = useMemo(() =>
	{

		const now = dayjs();

		// dayjs => sunday 0 , userHolidy => monday 0
		let wkDay = now.weekday() - 1;

		if (wkDay === -1)
		{
			wkDay = 6;
		}

		// 曜日
		if (userHoliday[wkDay] === 1)
		{
			return false;
		}

		const year = now.year();
		const month = now.month()+1;
		const date = now.date();

		let isHoliday = false;

		nationalHolidays.forEach((d) =>
		{
			if (year === d.year && month === d.month && date === d.day)
			{
				isHoliday = true;
			}
		});

		if (isHoliday)
		{
			return false;
		}

		const openTimeString = `${year}-${month}-${date} ${userOpenTime}:00`;
		const closeTimeString = `${year}-${month}-${date} ${userCloseTime}:00`;

		const openTimeObj = dayjs(openTimeString);
		const closeTimeObj = dayjs(closeTimeString);

		if (now > openTimeObj && now < closeTimeObj)
		{
			return true;
		}

		return false;
	}, [ userOpenTime, userCloseTime, userHoliday, nationalHolidays ]);

	const updateDimensions = useCallback(() =>
	{

		if (startButtonRef.current)
		{

			startButtonRef.current.style.visibility = 'visible';

			if (!isWorkTime)
			{
				startButtonRef.current.style.backgroundColor = '#aaaaaa';
				startButtonRef.current.style.color = 'white';
			}
			else if (buttonColor)
			{
				startButtonRef.current.style.backgroundColor = `#${buttonColor}`;
			}

			// PC
			if (window.innerWidth/window.innerHeight >= breakPointAspectRatio)
			{

				// button text size
				if (window.innerHeight < 400 || window.innerWidth < 500)
				{
					startButtonRef.current.style.fontSize = '0.75rem';
				}
				else if (window.innerHeight < 500 || window.innerWidth < 700)
				{
					startButtonRef.current.style.fontSize = '0.9rem';
				}
				else if (window.innerHeight < 650 || window.innerWidth < 1000)
				{
					startButtonRef.current.style.fontSize = '1.05rem';
				}
				else if (window.innerHeight < 800 || window.innerWidth < 1200)
				{
					startButtonRef.current.style.fontSize = '1.2rem';
				}
				else if (window.innerWidth < 1536)
				{
					startButtonRef.current.style.fontSize = '1.35rem';
				}
				else
				{
					startButtonRef.current.style.fontSize = '1.5rem';
				}

				// button location
				if (userBtnPositionObj.PC.verticCenter)
				{
					startButtonRef.current.style.top = '0';
					startButtonRef.current.style.bottom = '0';
				}
				else
				{
					startButtonRef.current.style.top = userBtnPositionObj.PC.top ? `${userBtnPositionObj.PC.top}%` : '';
					startButtonRef.current.style.bottom = userBtnPositionObj.PC.bottom ? `${userBtnPositionObj.PC.bottom}%` : '';
				}

				if (userBtnPositionObj.PC.horizCenter)
				{
					startButtonRef.current.style.left = '0';
					startButtonRef.current.style.right = '0';
				}
				else
				{
					startButtonRef.current.style.left = userBtnPositionObj.PC.left ? `${userBtnPositionObj.PC.left}%` : '';
					startButtonRef.current.style.right = userBtnPositionObj.PC.right ?`${userBtnPositionObj.PC.right}%` : '';
				}

			}
			else // SP
			{
				// button text size
				if (window.innerHeight < 300)
				{
					startButtonRef.current.style.fontSize = '0.75rem';
				}
				else if (window.innerHeight < 450)
				{
					startButtonRef.current.style.fontSize = '0.9rem';
				}
				else if (window.innerHeight < 600)
				{
					startButtonRef.current.style.fontSize = '1.05rem';
				}
				else if (window.innerHeight < 800)
				{
					startButtonRef.current.style.fontSize = '1.2rem';
				}
				else if (window.innerHeight < 1280)
				{
					startButtonRef.current.style.fontSize = '1.35rem';
				}
				else
				{
					startButtonRef.current.style.fontSize = '1.5rem';
				}
				// button location
				if (userBtnPositionObj.SP.verticCenter)
				{
					startButtonRef.current.style.top = '0';
					startButtonRef.current.style.bottom = '0';
					startButtonRef.current.style.left = '0';
					startButtonRef.current.style.right = '0';
				}
				else
				{
					startButtonRef.current.style.top = userBtnPositionObj.SP.top ? `${userBtnPositionObj.SP.top}%` : '';
					startButtonRef.current.style.bottom = userBtnPositionObj.SP.bottom ? `${userBtnPositionObj.SP.bottom}%` : '';
					startButtonRef.current.style.left = '0';
					startButtonRef.current.style.right = '0';
				}
			}
		}

		if (bgImgRef.current && bgImgAreaRef.current && startButtonRef.current)
		{

			// PC case. window height is smaller than background image
			if (window.innerWidth/window.innerHeight >= breakPointAspectRatio)
			{
				// img size is limited by Height (horizontally long device)
				if (window.innerHeight <= bgImgRef.current.clientHeight)
				{
					bgImgAreaRef.current.style.height = '100%';
					bgImgAreaRef.current.style.width = '';
					if (bgImgRef.current.clientWidth < 900)
					{
						startButtonRef.current.style.padding = '1% 2%';
					}
					else
					{
						startButtonRef.current.style.padding = '2% 4%';
					}
				}
				else
				{
					bgImgAreaRef.current.style.height = '';
					bgImgAreaRef.current.style.width = '100%';
					if (bgImgRef.current.clientWidth < 700)
					{
						startButtonRef.current.style.padding = '1% 1%';
					}
					else
					{
						startButtonRef.current.style.padding = '2% 4%';
					}
				}
			}

			// Smart phone case. window width is smaller than background image
			else
			// img size is limited by Width (vertically long device)
			if (window.innerWidth <= bgImgRef.current.clientWidth)
			{
				bgImgAreaRef.current.style.width = '100%';
				bgImgAreaRef.current.style.height = '';
				startButtonRef.current.style.padding = '2% 2%';
			}
			else
			{
				bgImgAreaRef.current.style.width = '';
				bgImgAreaRef.current.style.height = '100%';
				startButtonRef.current.style.padding = '2% 4%';
			}

		}

		setAspectRatio(window.innerWidth/window.innerHeight);

	}, [ buttonColor, userBtnPositionObj, isWorkTime ]);

	useEffect(() =>
	{
		window.addEventListener('resize', updateDimensions);

		return () => window.removeEventListener('resize', updateDimensions);

	}, [ updateDimensions ]);

	const fetchInfos = useCallback(() =>
	{
		const parts = window.location.hostname.split('.');
		const subdomain = parts.shift();

		firestore.collection('users').where('subdomain', '==', subdomain)
			.get()
			.then((querySnapshot) =>
			{
				querySnapshot.forEach((doc) =>
				{
					if (doc.exists)
					{
						const userData = doc.data();

						setUserLogoUrl(userData.logoUrl ? userData.logoUrl : '');
						setUserBgPCUrl(userData.bgPCUrl ? userData.bgPCUrl : '');
						setUserBgSPUrl(userData.bgSPUrl ? userData.bgSPUrl : '');
						setUserOpenTime(userData.openTime ? userData.openTime : '10:00');
						setUserCloseTime(userData.closeTime ? userData.closeTime : '18:00');
						setUserHoliday(userData.holiday ? userData.holiday : [ 0, 0, 0, 0, 0, 0, 0 ]);
						setUserTell(userData.tell ? userData.tell : '0120-111-1111');
						setButtonColor(userData.buttonColor ? userData.buttonColor : 'F15A24');
						setButtonText(userData.buttonText ? userData.buttonText : 'オンライン相談受付');
						setBgPCGradient(userData.bgPCGradient ? userData.bgPCGradient : '#EEEEEE, #EEEEEE');
						setBgSPGradient(userData.bgSPGradient ? userData.bgSPGradient : '#EEEEEE, #EEEEEE');
						setDirectMode(userData.directMode ? true: false);
						if (userData.btnPosition
						&& userData.btnPosition.PC
						&& userData.btnPosition.SP)
						{
							setUserBtnPositionObj(userData.btnPosition);
						}
						else
						{
							setUserBtnPositionObj({
								PC : { top: '44', bottom: '', left: '6', right: '', verticCenter: false, horizCenter: false },
								SP : { top: '49', bottom: '', verticCenter: false }
							});
						}

						if (userData.nationalHoliday)
						{
							firestore.collection('nationalHolidaysJP').orderBy('createdAt', 'desc')
								.get()
								.then((document) =>
								{
									const nationalHolidaysAr = document.docs[0]?.data()?.holidays;

									if (nationalHolidaysAr)
									{
										setNationalHolidays(
											nationalHolidaysAr.filter(
												(h) => Number(h.type) <= Number(userData.nationalHoliday)
											)
										);
									}
								});
						}
					}
				});
			});
	}, []);

	const askForPerms = () =>
	{
		if (mediaPerms.video || mediaPerms.audio)
		{
			navigator.mediaDevices.getUserMedia(mediaPerms);
		}
	};

	const handleJoin = () =>
	{
		askForPerms();
		setNoticeJoinRoomDialogOpen(true);
	};

	useEffect(() =>
	{
		fetchInfos();
	}, [ fetchInfos ]);

	return (
		<div
			className={classes.root}
			style={aspectRatio < breakPointAspectRatio ? { background: `linear-gradient(${bgSPGradient})` } :{ background: `linear-gradient(${bgPCGradient})` }}
		>
			{ aspectRatio < breakPointAspectRatio ?
				<>

					<div className={classes.container}>
						<div className={classes.imageArea} ref={bgImgAreaRef}>
							{ userBgSPUrl &&
							<img
								className={classes.imageContentSP}
								src={userBgSPUrl}
								alt='PageImage'
								ref={bgImgRef}
								onLoad={updateDimensions}
							/>
							}

							{ userLogoUrl &&
								<img
									className={classes.logo}
									src={userLogoUrl}
									alt='Logo'
								/>
							}

							<Button
								onClick={isWorkTime ? handleJoin : () => {}}
								className={`${classes.joinButton} ${classes.joinButtonSP} ${classes.joinButtonFontSize}`}
								ref={startButtonRef}
								id='joinButton'
							>
								{ isWorkTime ? buttonText : '受付時間外です' }
							</Button>
						</div>
					</div>

				</>
				:
				<>

					<div className={classes.container}>
						<div className={classes.imageArea} ref={bgImgAreaRef}>
							{ userBgPCUrl &&
							<img
								className={classes.imageContentPC}
								src={userBgPCUrl}
								alt='PageImage'
								ref={bgImgRef}
								onLoad={updateDimensions}
							/>
							}

							{ userLogoUrl &&
								<img
									className={classes.logo}
									src={userLogoUrl}
									alt='Logo'
								/>
							}

							<Button
								onClick={isWorkTime ? handleJoin : () => {}}
								className={`${classes.joinButton} ${classes.joinButtonPC} ${classes.joinButtonFontSize}`}
								ref={startButtonRef}
								id='joinButton'
							>
								{ isWorkTime ? buttonText : '受付時間外です' }
							</Button>

						</div>
					</div>

				</>
			}
			<NoticeJoinRoomDialog directMode={directMode}/>
		</div>
	);
};

GuestLobby.propTypes =
{
	room                        : PropTypes.object.isRequired,
	setMediaPerms  	            : PropTypes.func.isRequired,
	classes                     : PropTypes.object.isRequired,
	mediaPerms                  : PropTypes.object.isRequired,
	locale                      : PropTypes.string.isRequired,
	localesList                 : PropTypes.array.isRequired,
	setNoticeJoinRoomDialogOpen : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		room        : state.room,
		mediaPerms  : state.settings.mediaPerms,
		locale      : state.intl.locale,
		localesList : state.intl.list
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setMediaPerms : (mediaPerms) =>
		{
			dispatch(settingsActions.setMediaPerms(mediaPerms));
		},
		setNoticeJoinRoomDialogOpen : (noticeJoinRoomDialogOpen) =>
		{
			dispatch(roomActions.setNoticeJoinRoomDialogOpen(noticeJoinRoomDialogOpen));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room === next.room &&
				prev.intl.locale === next.intl.locale &&
				prev.intl.localesList === next.intl.localesList
			);
		}
	}
)(withStyles(styles)(GuestLobby)));
