export function getSignalingUrl(peerId, roomId, subdomain, clientType)
{
	const hostname = window.config.serverHostname || window.location.hostname;
	const port =
		process.env.NODE_ENV !== 'production' ?
			window.config.developmentPort
			:
			window.config.productionPort;

	const url =
		process.env.NODE_ENV !== 'production' ?
			`ws://${hostname}:${port}/?peerId=${peerId}&roomId=${roomId}&subdomain=${subdomain}&clientType=${clientType}`
			:
			`wss://${hostname}:${port}/?peerId=${peerId}&roomId=${roomId}&subdomain=${subdomain}&clientType=${clientType}`;

	return url;
}
