import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import { connect } from 'react-redux';
import * as meActions from '../../../actions/meActions';

// material-ui
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const styles = () =>
	({
		root :
		{
			height          : '100%',
			width           : '100%',
			backgroundColor : 'rgb(228, 228, 225)',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			margin          : '0'
		},
		container :
		{
			margin          : '2%',
			borderRadius    : '10px',
			backgroundColor : '#FFF',
			maxWidth        : '700px',
			padding         : '0 0 60px 0 !important',
			boxShadow       : '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
		},
		header : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between',
			padding        : '2%'
		},
		logo :
		{
			width        : 'auto',
			height       : '2.5rem',
			marginBottom : '1%'
		},
		article :
		{
			maxWidth    : '700px',
			alignSelf   : 'center',
			marginLeft  : '3%',
			marginRight : '3%'
		},
		title :
		{
			marginTop : '50px',
			fontSize  : '1.4rem',
			color     : '#777676',
			width     : '100%',
			textAlign : 'center'
		},
		message :
		{
			fontSize  : '1rem',
			color     : '#999898',
			width     : '100%',
			textAlign : 'center',
			marginTop : '40px;'
		},
		buttonWrapper :
		{
			marginTop      : '40px',
			display        : 'flex',
			justifyContent : 'center'
		},
		backButton :
		{
			color           : 'white',
			backgroundColor : '#9ec317',
			boxShadow       : '2px 2px 4px #aaa',
			borderRadius    : '0.5rem',
			margin          : '0 auto',
			fontSize        : '1.1rem',
			padding         : '5px 40px 5px 40px'
		}
	});

const ServiceStoppedPage = ({
	classes,
	isAdmin,
	changeViewMode,
	setUser
}) =>
{

	const exitFromView = () =>
	{

		// administrator
		if (isAdmin)
		{
			changeViewMode();
		}
		// operator
		else
		{
			setUser(null);
		}
	};

	return (
		<Box className={classes.root}>

			<Box className={classes.container}>
				<Box className={classes.header}>
					<img
						alt='logo'
						src='images/main_logo.png'
						className={classes.logo}
					/>
				</Box>
				<Box className={classes.article}>
					<Typography variant='h1' className={classes.title}>
						お客様の契約状況ではサービスをご利用できません
					</Typography>

					<Typography variant='body1' className={classes.message}>
						だいまお客様のご都合により、サービスのご利用を一時停止しております。サービスの再開をご希望の場合は、管理者より契約会社までお問い合わせください。
					</Typography>

					<Box className={classes.buttonWrapper}>
						<Button
							variant='contained'
							color='secondary'
							className={classes.backButton}
							onClick={exitFromView}
						>
							戻る
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

ServiceStoppedPage.propTypes =
{
	classes        : PropTypes.object.isRequired,
	isAdmin        : PropTypes.bool,
	changeViewMode : PropTypes.func,
	setUser        : PropTypes.func.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setUser : (bool) =>
		{
			dispatch(meActions.setUser(bool));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ServiceStoppedPage)));
