const initialState =
{
	currentNaviTab : 'home',
	chatEnabled    : false
};

const bottomNavigation = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_NAVI_TAB':
		{
			const { naviTab } = action.payload;

			return { ...state, currentNaviTab: naviTab };
		}

		case 'SET_CHAT_ENABLED':
		{
			const { chatEnabled } = action.payload;

			return { ...state, chatEnabled };
		}

		default:
			return state;
	}
};

export default bottomNavigation;
