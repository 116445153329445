export const addUserMessage = (text) =>
	({
		type    : 'ADD_NEW_USER_MESSAGE',
		payload : { text }
	});

export const addResponseMessage = (message) =>
	({
		type    : 'ADD_NEW_RESPONSE_MESSAGE',
		payload : { message }
	});

export const uploadedShareFile = (fileInfo) =>
	({
		type    : 'UPLOADED_SHARE_FILE',
		payload : { fileInfo }
	});

export const recieveNewShareFile = (fileInfo) =>
	({
		type    : 'RECIEVE_NEW_SHARE_FILE',
		payload : { fileInfo }
	});

export const addChatHistory = (chatHistory) =>
	({
		type    : 'ADD_CHAT_HISTORY',
		payload : { chatHistory }
	});

export const clearChat = () =>
	({
		type : 'CLEAR_CHAT'
	});