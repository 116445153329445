import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import * as adminActions from '../../../actions/adminActions';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { auth, firestore } from '../../../lib/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
// Constants
import {
	PLANS,
	ROOM_OFFLINE,
	ROOM_ONLINE,
	ROOM_AFK,
	ROOM_CALLING,
	ROOM_TALKING
} from '../../../const';

// components
import ConfirmDialog from '../Common/ConfirmDialog';
import { Room } from '../../appPropTypes';

const styles = () =>
	({
		root :
		{
			width    : '100%',
			minWidth : 1200,
			position : 'relative',
			padding  : '0% 2% 2% 2%',
			color    : 'var(--text-color)'
		},
		wrapper :
		{
			width           : '100%',
			height          : '85vh',
			backgroundColor : 'white',
			borderRadius    : '5px',
			boxShadow       : '3px 3px 10px #aaa',
			padding         : '2%',
			overflow        : 'hidden'
		},
		tableBg :
		{
			display  : 'flex',
			position : 'absolute',
			height   : '68%',
			width    : '93%',
			zIndex   : '0',
			overflow : 'auto'
		},
		tableBgLine :
		{
			height      : '100%',
			borderRight : '1px solid #ccc'
		},
		tableHeader :
		{
			display : 'flex'
		},
		tableHeaderText : {
			fontSize  : '0.8rem',
			textAlign : 'center',
			padding   : '1rem 0'
		},
		tableData :
		{
			display      : 'flex',
			listStyle    : 'none',
			marginBottom : '0.8rem'
		},
		tableDataLine :
		{
			listStyle     : 'none',
			fontSize      : '0.8rem',
			textAlign     : 'center',
			paddingBottom : '1rem'
		},
		roomNo :
		{
			width        : '10%',
			paddingRight : '0.5%'
		},
		roomNoData :
		{
			border       : 'none',
			borderRadius : '0.5rem',
			background   : '#999898',
			padding      : '1.2rem',
			color        : 'white',
			textAlign    : 'center'
		},
		tableDataBgLeft : {
			display      : 'flex',
			width        : '55%',
			border       : 'none',
			borderRadius : '0.5rem',
			background   : 'transparent',
			alignItems   : 'center',
			paddingLeft  : '1%',
			zIndex       : 1
		},
		tableDataBgRight : {
			display      : 'flex',
			width        : '45%',
			border       : 'none',
			borderRadius : '0.5rem',
			background   : 'transparent',
			alignItems   : 'center',
			marginLeft   : '1%',
			zIndex       : 1
		},
		tableDataValuesLeft : {
			display      : 'flex',
			width        : '55%',
			border       : 'none',
			borderRadius : '0.5rem',
			background   : 'rgba(0,0,0,0.1)',
			alignItems   : 'center',
			marginLeft   : '0.25%',
			zIndex       : 1
		},
		tableDataValuesRight : {
			display      : 'flex',
			width        : '45%',
			border       : 'none',
			borderRadius : '0.5rem',
			background   : 'rgba(0,0,0,0.1)',
			alignItems   : 'center',
			marginLeft   : '1%',
			zIndex       : 1
		},
		tableDataValue : {
			padding       : '1.2rem 0',
			margin        : '0 0.5rem 0 1rem',
			color         : '#888',
			paddingBottom : '1rem',
			textAlign     : 'center',
			fontSize      : '0.8rem'
		},
		roomName :
		{
			width        : '60%',
			paddingLeft  : '0.5%',
			paddingRight : '0.5%',
			'& > input'  : {
				backgroundColor : 'white',
				border          : '1px solid #ccc',
				borderRadius    : '0.5rem',
				padding         : '0.3rem 0.5rem',
				boxShadow       : '2px 2px 4px inset #eee',
				color           : '#999898',
				fontSize        : '0.9rem',
				width           : '95%'
			}
		},
		roomPass :
		{
			width        : '40%',
			paddingLeft  : '0.5%',
			paddingRight : '0.5%',
			'& > input'  : {
				backgroundColor : 'white',
				border          : '1px solid #ccc',
				borderRadius    : '0.5rem',
				padding         : '0.3rem 0.5rem',
				boxShadow       : '2px 2px 4px inset #eee',
				color           : '#999898',
				fontSize        : '0.9rem',
				width           : '95%'
			}
		},
		roomStatus :
		{
			width : '30%'
		},
		roomUser :
		{
			width : '52%'
		},
		roomAction :
		{
			width          : '18%',
			border         : 'none',
			justifyContent : 'center',
			display        : 'flex'
		},
		roomNameBg : {
			textAlign : 'left'
		},
		statusBg : {
			background   : '#999898',
			padding      : '0.2rem 0',
			borderRadius : '0.3rem',
			color        : 'white'
		},
		statusBgWaiging : {
			background : '#9ec317'
		},
		statusBgTalking : {
			background : '#eb8000'
		},
		userBg : {
			background   : '#999898',
			padding      : '0.2rem 0.5rem',
			borderRadius : '0.3rem',
			color        : 'white',
			textAlign    : 'left',
			height       : '1.5rem'
		},
		actionBtn : {
			background   : 'white',
			border       : '1px solid #acacac',
			borderRadius : '0.3rem',
			color        : '#acacac',
			width        : '3.6rem',
			padding      : '0.1rem',
			fontSize     : '0.7rem',
			cursor       : 'pointer',
			'&:hover'    :
			{
				backgroundColor : '#c0d748',
				color           : 'white'
			}
		},
		top :
		{
			display : 'flex',
			height  : '15%'
		},
		topLeft :
		{
			width       : '50%',
			marginRight : '2%'
		},
		topRight :
		{
			width : '48%'
		},
		topData :
		{
			height     : '2rem',
			display    : 'flex',
			margin     : '2% 0',
			alignItems : 'center',
			lineHeight : 1
		},
		center :
		{
			width        : '100%',
			height       : '74%',
			overflow     : 'auto',
			marginBottom : '1%'
		},
		inputLabel :
		{
			fontSize    : '0.8rem',
			width       : '7rem',
			whiteSpace  : 'nowrap',
			marginRight : '2%'
		},
		inputValue :
		{
			fontSize   : '0.8rem',
			whiteSpace : 'nowrap'
		},
		inputValueRight :
		{
			fontSize   : '0.8rem',
			whiteSpace : 'nowrap',
			marginLeft : '1rem'
		},
		inputBtn :
		{
			marginLeft      : '5%',
			backgroundColor : 'white',
			border          : '1px solid #ccc',
			borderRadius    : '0.3rem',
			padding         : '0.1rem 1rem',
			fontSize        : '0.8rem',
			boxShadow       : 'none'
		},
		timeCheckBtn :
		{
			backgroundColor : 'white',
			border          : '1px solid #ccc',
			borderRadius    : '0.5rem',
			padding         : '0.1rem',
			fontSize        : '0.8rem',
			width           : '12rem',
			textAlign       : 'center',
			boxShadow       : 'none',
			color           : '#999898'
		},
		inputField :
		{
			backgroundColor : '#878686',
			border          : '1px solid #ccc',
			borderRadius    : '0.5rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #666',
			color           : 'white',
			fontSize        : '0.9rem',
			width           : '12rem'
		},
		inputSwitch :
		{
			marginLeft : '5%',
			whiteSpace : 'nowrap'
		},
		description :
		{
			marginBottom : '0.3%',
			fontSize     : '0.9rem',
			marginLeft   : '0%'
		},
		settings :
		{
			marginTop      : '1%',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center'
		},
		settingItem :
		{
			display     : 'flex',
			alignItems  : 'center',
			marginRight : '5%'
		},
		settingLabel :
		{
			marginRight : '5%',
			whiteSpace  : 'nowrap'
		},
		settingList :
		{
			border       : 'none',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			boxShadow    : '2px 2px 4px #ccc',
			color        : '#999898'
		},
		settingWarning :
		{
			textAlign : 'right',
			fontSize  : '0.8rem'
		},
		saveBtn :
		{
			marginLeft      : '1%',
			backgroundColor : '#9ec317',
			border          : '1px solid #ccc',
			borderRadius    : '0.3rem',
			padding         : '0.1rem 0.7rem',
			fontSize        : '0.8rem',
			boxShadow       : 'none',
			color           : 'white',
			whiteSpace      : 'nowrap',
			position        : 'absolute',
			right           : '4%',
			'&:hover'       :
			{
				backgroundColor : '#c0d748',
				color           : 'white'
			}
		},
		changed :
		{
			borderColor : '#4169e1 !important',
			borderWidth : '2px !important'
		},
		bottom :
		{
			position        : 'absolute',
			width           : '93%',
			bottom          : '4.5%',
			zIndex          : '100',
			backgroundColor : '#FFF'
		},
		bottomWrapper :
		{
			marginTop    : '0.2%',
			border       : '1px solid #ccc',
			borderRadius : '0.5rem',
			padding      : '0.5% 0.5% 1% 0.5%'
		}
	});

const getPlanName = (planId) =>
{
	if (planId === 0 || planId)
	{
		return PLANS.find((plan) => plan.id === planId).name;
	}
	else
	{
		return '不明';
	}
};

const HomeAdminView = ({
	classes,
	setAdminUsageDialogOpen,
	setAdminUser,
	setAdminCalls
}) =>
{
	const [ admin ] = useAuthState(auth);

	const [ settings, setSettings ] = useState(null);
	const [ currentRooms, setCurrentRooms ] = useState([]);
	const [ userName, setUserName ] = useState('');
	const [ roomNum, setUserRoomNum ] = useState(0);
	const [ serviceName, setUserServiceName ] = useState('');
	const [ userPlanName, setUserPlanName ] = useState('');
	const [ useOneDayPassword, setUseOneDayPassword ] = useState(false);
	const [ userOneDayPassword, setUserOneDayPassword ] = useState('');
	const [ userStaticPassword, setUserStaticPassword ] = useState('');
	const [ roomList, setUserRoomList ] = useState([]);
	const [ callNum, setUserCallNum ] = useState(3);
	const [ relayNum, setUserRelayNum ] = useState(1);
	const [ callPriority, setUserCallPriority ] = useState(0);

	const [ forceLogoutRooms, setForceLogoutRooms ] = useState({});

	const [ confirmDialogState, setConfirmDialogState ] = useState(
		{
			show            : false,
			title           : '',
			message         : '',
			acceptMethod    : () => {},
			acceptMethodArg : undefined,
			closeButtonOnly : true,
			success         : false
		}
	);

	const closeConfirmDialog = () =>
	{
		setConfirmDialogState(
			{
				show            : false,
				title           : '',
				message         : '',
				acceptMethod    : () => {},
				acceptMethodArg : undefined,
				closeButtonOnly : true
			}
		);
	};

	const generatePassowrd = (length = 8) =>
	{
		const passwordNumeric = '1234567890';
		const passwordUppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		const passwordLowercase = 'abcdefghijklmnopqrstuvwxyz';

		let password = '';

		let passwordBase = '';

		// 生成ルールの確認
		passwordBase += passwordNumeric;
		passwordBase += passwordUppercase;
		passwordBase += passwordLowercase;

		for (let i = 0; i < length; i++)
		{
			password += passwordBase.charAt(Math.floor(Math.random() * passwordBase.length));
		}

		return password;
	};

	const handleChangeOneDayPassword = (e) =>
	{
		setUserOneDayPassword(e.target.value);
	};

	const handleChangeStaticPassword = (e) =>
	{
		setUserStaticPassword(e.target.value);
	};

	const handleChangeRoomName = (index, e) =>
	{
		roomList[index].name = e.target.value;
		setUserRoomList([ ...roomList ]);
	};

	const handleChangeRoomPassword = (index, e) =>
	{
		roomList[index].password = e.target.value;
		setUserRoomList([ ...roomList ]);
	};

	const handleChangeCallNum = (e) =>
	{
		setUserCallNum(e.target.value);
	};

	const handleChangeRelayNum = (e) =>
	{
		setUserRelayNum(e.target.value);
	};

	const handleChangeCallPriority = (e) =>
	{
		setUserCallPriority(e.target.value);
	};

	const handleToggleSwitch = () =>
	{
		setUseOneDayPassword(!useOneDayPassword);
		setUserOneDayPassword(generatePassowrd());
	};

	const handleShowTimeHistory = () =>
	{
		setAdminUsageDialogOpen(true);
	};

	const fetchUser = () =>
	{
		if (admin)
		{
			firestore.collection('users').doc(admin.uid)
				.get()
				.then((querySnapshot) =>
				{
					const user = {
						id : querySnapshot.id,
						...querySnapshot.data()
					};

					setUserName(user && user.name ? user.name : '');
					setUserRoomNum(user && user.roomNum ? user.roomNum : 0);
					setUserServiceName(user && user.serviceName ? user.serviceName : '');
					setUserPlanName(user && user.plan ? getPlanName(user.plan) : '');

					setAdminUser(user);
				});
		}
	};

	const fetchSetting = () =>
	{
		if (admin)
		{
			firestore.collection('settings').doc(admin.uid)
				.get()
				.then((querySnapshot) =>
				{
					const data = {
						id : querySnapshot.id,
						...querySnapshot.data()
					};

					setSettings(data);
					setUseOneDayPassword(
						data && data.useOneDayPassword
							? data.useOneDayPassword
							: false);
					setUserOneDayPassword(data && data.oneDayPassword ? data.oneDayPassword : '');
					setUserStaticPassword(data && data.staticPassword ? data.staticPassword : '');
					setUserCallNum(data && data.callNum ? data.callNum : 3);
					setUserRelayNum(data && data.relayNum ? data.relayNum : 1);
					setUserCallPriority(data && data.callPriority ? data.callPriority : 0);
				});
		}
	};

	const fetchRooms = () =>
	{
		if (admin)
		{
			firestore.collection('rooms')
				.where('uid', '==', admin.uid)
				.get()
				.then((snapshot) =>
				{
					const rooms = [];
					const current = [];

					snapshot.forEach((doc) =>
					{
						rooms.push({
							id : doc.id,
							...doc.data()
						});
						current.push({
							id : doc.id,
							...doc.data()
						});
					});

					rooms.sort((a, b) =>
					{
						if (a.index < b.index) { return -1; }
						if (a.index > b.index) { return 1; }

						return 0;
					});

					current.sort((a, b) =>
					{
						if (a.index < b.index) { return -1; }
						if (a.index > b.index) { return 1; }

						return 0;
					});

					setUserRoomList(rooms.length > 0 ? rooms : []);
					setCurrentRooms(current.length > 0 ? current : []);
				});
		}
	};

	const fetchCalls = () =>
	{
		if (admin)
		{
			firestore.collection('calls').where('uid', '==', admin.uid)
				.orderBy('timestamp', 'desc')
				.onSnapshot((snapshot) =>
				{
					const calls = [];

					snapshot.forEach((doc) =>
					{
						calls.push({
							id : doc.id,
							...doc.data()
						});
					});
					setAdminCalls(calls);
				});
		}
	};

	const saveRoom = async (room) =>
	{
		await firestore.collection('rooms').doc(room.id)
			.update({
				index     : room.index,
				uid       : room.uid,
				subdomain : room.subdomain,
				name      : room.name,
				password  : room.password,
				status    : room.status,
				user      : room.user
			});
	};

	const saveRooms = async () =>
	{
		setCurrentRooms(...roomList);
		for (let i = 0; i < roomList.length; i++)
		{
			await saveRoom(roomList[i]);
		}
	};

	const saveSettings = () =>
	{

		const passwordPattern = /^[0-9a-zA-Z]*$/;

		if (userStaticPassword.length < 8)
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : '固定パスワードは8文字以上に設定してください。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true,
					success         : false

				}
			);
		}
		else if (!passwordPattern.test(userStaticPassword))
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : '固定パスワードに使用できるのは半角英数字のみです。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true,
					success         : false
				}
			);
		}
		else if (useOneDayPassword && userOneDayPassword.length < 8)
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : '1dayパスワードは8文字以上に設定してください。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true,
					success         : false
				}
			);
		}
		else if (useOneDayPassword && !passwordPattern.test(userOneDayPassword))
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : '1dayパスワードに使用できるのは半角英数字のみです。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true,
					success         : false
				}
			);
		}
		else
		{
			firestore.collection('settings').doc(settings.id)
				.update({
					uid               : settings.uid,
					subdomain         : settings.subdomain,
					useOneDayPassword : useOneDayPassword,
					oneDayPassword    : userOneDayPassword,
					staticPassword    : userStaticPassword,
					callNum           : parseInt(callNum),
					relayNum          : parseInt(relayNum),
					callPriority      : parseInt(callPriority)
				})
				.then(() =>
				{
					saveRooms();
					setConfirmDialogState(
						{
							show            : true,
							title           : '完了',
							message         : '保存しました',
							acceptMethod    : () => {},
							acceptMethodArg : undefined,
							closeButtonOnly : true,
							success         : true
						}
					);
				})
				.catch((e) =>
				{
					console.error(e); // eslint-disable-line no-console
				});
		}
	};

	const forceLeave = (arg) =>
	{
		firestore.collection('rooms').doc(arg.id)
			.get()
			.then((doc) =>
			{

				let statusUpdate = {
					status     : 0,
					user       : '',
					forceLeave : true
				};

				if (doc.data().status === ROOM_TALKING)
				{
					statusUpdate = { forceLeave: true };
				}

				firestore.collection('rooms').doc(arg.id)
					.update(
						{
							...statusUpdate
						}
					)
					.then(() =>
					{
						if (arg.status === ROOM_TALKING)
						{
							setConfirmDialogState({
								show            : true,
								title           : '完了',
								message         : `room${arg.index+1}は通話終了後に強制退室されます。`,
								acceptMethod    : () => {},
								acceptMethodArg : undefined,
								closeButtonOnly : true
							});

							setForceLogoutRooms((state) =>
							{
								const roomObj = {};

								roomObj[arg.index] = true;

								return {
									...state,
									...roomObj
								};
							});

						}
						else
						{
							setConfirmDialogState({
								show            : true,
								title           : '完了',
								message         : `room${arg.index+1}を強制退室させました`,
								acceptMethod    : () => {},
								acceptMethodArg : undefined,
								closeButtonOnly : true
							});
							setForceLogoutRooms({});
							fetchRooms();
						}

					})
					.catch(() =>
					{
						setConfirmDialogState({
							show            : true,
							title           : 'エラー',
							message         : '強制退室に失敗しました',
							acceptMethod    : () => {},
							acceptMethodArg : undefined,
							closeButtonOnly : true
						});
					});
			});

	};

	const forceLeaveHandler = (item) =>
	{
		setConfirmDialogState({
			show            : true,
			title           : '確認',
			message         : `room${item.index+1}を強制退室させますか?`,
			acceptMethod    : forceLeave,
			acceptMethodArg : { id: item.id, index: item.index, status: item.status },
			closeButtonOnly : false
		});
	};

	useEffect(() =>
	{
		fetchUser();
		fetchSetting();
		fetchRooms();
		fetchCalls();
		// eslint-disable-next-line
	}, [ admin ]);

	return (
		<>
			<ConfirmDialog
				show={confirmDialogState.show}
				title={confirmDialogState.title}
				message={confirmDialogState.message}
				accept={confirmDialogState.acceptMethod}
				acceptArg={confirmDialogState.acceptMethodArg}
				cancel={closeConfirmDialog}
				closeButtonOnly={confirmDialogState.closeButtonOnly}
			/>
			<div className={classes.root}>
				<div className={classes.wrapper}>
					<div className={classes.top}>
						<div className={classes.topLeft}>
							<div className={classes.topData}>
								<div className={classes.inputLabel}>契約者名</div>
								<div className={classes.inputValue}>
									<div className={classes.inputField}>{userName}</div>
								</div>
								<div className={classes.inputValueRight}>
									<div className={classes.inputField}>{userPlanName}▶{roomNum}室</div>
								</div>
							</div>
							<div className={classes.topData}>
								<div className={classes.inputLabel}>サービス名</div>
								<div className={classes.inputValue}>
									<div className={classes.inputField}>{serviceName}</div>
								</div>
								<div className={classes.inputValueRight}>
									<Button className={classes.timeCheckBtn} onClick={handleShowTimeHistory} variant='contained'>通話時間確認</Button>
								</div>
							</div>
						</div>
						<div className={classes.topRight}>
							<div className={classes.topData}>
								<div className={classes.inputLabel}>1dayパスワード</div>
								<div className={classes.inputValue}>
									{ (useOneDayPassword === false) ?
										<input value='' className={classes.inputField} type='text' disabled/>
										:
										<input value={userOneDayPassword} className={classnames(
											classes.inputField,
											settings && settings.oneDayPassword !== userOneDayPassword
												? classes.changed : null
										)} onChange={handleChangeOneDayPassword} type='text'
										/>
									}
								</div>
								<div className={classes.inputSwitch}>
									OFF
									<Switch
										edge='end'
										onChange={handleToggleSwitch}
										checked={useOneDayPassword === true}
										inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
									/>
									ON
								</div>
								<Button onClick={saveSettings} className={classes.saveBtn} variant='contained'>
									設定保存
								</Button>
							</div>
							<div className={classes.topData}>
								<div className={classes.inputLabel}>固定パスワード</div>
								<div className={classes.inputValue}>
									<input value={userStaticPassword} className={classnames(
										classes.inputField,
										settings && settings.staticPassword !== userStaticPassword
											? classes.changed : null
									)} onChange={handleChangeStaticPassword} type='text'
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={classes.center}>
						<div className={classes.description}>
							ルーム名もしくはパスワードを入力後「設定保存」ボタンを押すと反映されます。
						</div>

						<ul>
							<div className={classes.tableBg}>
								<div className={`${classes.tableBgLine} ${classes.roomNo}`} />
								<div className={`${classes.tableDataBgLeft}`}>
									<div className={`${classes.tableBgLine} ${classes.roomName}`} />
									<div className={`${classes.tableBgLine} ${classes.roomPass}`} />
								</div>
								<div className={`${classes.tableDataBgRight}`}>
									<div className={`${classes.tableBgLine} ${classes.roomStatus}`} />
									<div className={`${classes.tableBgLine} ${classes.roomUser}`} />
									<div className={`${classes.tableBgLine} ${classes.roomAction}`} />
								</div>
							</div>
							<div className={classes.tableHeader}>
								<div className={`${classes.tableHeaderText} ${classes.roomNo}`}>
									ルームNo.
								</div>
								<div className={`${classes.tableDataBgLeft}`}>
									<div className={`${classes.tableHeaderText} ${classes.roomName}`}>
										ルーム名
									</div>
									<div className={`${classes.tableHeaderText} ${classes.roomPass}`}>
										PASS
									</div>
								</div>
								<div className={`${classes.tableDataBgRight}`}>
									<div className={`${classes.tableHeaderText} ${classes.roomStatus}`}>
										ステータス
									</div>
									<div className={`${classes.tableHeaderText} ${classes.roomUser}`}>
										利用者
									</div>
									<div className={`${classes.tableHeaderText} ${classes.roomAction}`}>
										操作
									</div>
								</div>
							</div>
							{roomList.length < 1 ? (
								<div>no items</div>
							) : (
								roomList.map((item, index) =>
									(<li className={classes.tableData} key={index}>
										<div className={`${classes.roomNo}`}>
											<div className={classes.roomNoData}>room.{index + 1}</div>
										</div>
										<div className={`${classes.tableDataValuesLeft}`}>
											<div className={
												classnames(classes.tableDataValue, classes.roomName)}
											>
												<input
													value={item.name}
													className={classnames(
														currentRooms[index]
													&& currentRooms[index].name !== roomList[index].name
															? classes.changed : null
													)}
													onChange={(value) => handleChangeRoomName(index, value)}
													type='text'
												/>
											</div>
											<div className={
												classnames(classes.tableDataValue, classes.roomPass)}
											>
												<input
													value={item.password}
													className={classnames(
														currentRooms[index]
													&& currentRooms[index].password !== roomList[index].password
															? classes.changed : null
													)}
													onChange={(value) => handleChangeRoomPassword(index, value)}
													type='text'
												/>
											</div>
										</div>

										<div className={`${classes.tableDataValuesRight}`}>
											<div className={`${classes.tableDataValue} ${classes.roomStatus}`}>
												{ item.status === ROOM_OFFLINE &&
												<div className={`${classes.statusBg}`}>オフライン</div>
												}
												{ (item.status === ROOM_ONLINE
												|| item.status === ROOM_CALLING) &&
												<div className={`${classes.statusBg} ${classes.statusBgWaiging}`}>待機中</div>
												}
												{ item.status === ROOM_TALKING &&
												<div className={`${classes.statusBg} ${classes.statusBgTalking}`}>通話中</div>
												}
												{ item.status === ROOM_AFK &&
												<div className={classes.statusBg}>離席中</div>
												}
											</div>
											<div className={`${classes.tableDataValue} ${classes.roomUser}`}>
												<div className={classes.userBg}>{item.user}</div>
											</div>
											<div className={`${classes.tableDataValue} ${classes.roomAction}`}>

												{
													(item.status !== ROOM_OFFLINE
														&& !forceLogoutRooms[item.index]) &&
														<div
															className={classes.actionBtn}
															onClick={() => forceLeaveHandler(item)}
														>
															強制退室
														</div>
												}
											</div>
										</div>

									</li>)
								))}
						</ul>
					</div>
					<div className={classes.bottom}>
						<div className={classes.bottomWrapper}>
							<div className={classes.settings}>
								<div className={classes.settingItem}>
									<div className={classes.settingLabel}>コール回数の設定</div>
									<select
										value={callNum}
										className={classnames(
											classes.settingList,
											settings && settings.callNum !== callNum
												? classes.changed : null
										)}
										onChange={handleChangeCallNum}
									>
										<option value='5'>5秒</option>
										<option value='10'>10秒</option>
										<option value='15'>15秒</option>
										<option value='20'>20秒</option>
										<option value='25'>25秒</option>
										<option value='30'>30秒</option>
									</select>
								</div>
								<div className={classes.settingItem}>
									<div className={classes.settingLabel}>リレー回数の設定</div>
									<select
										value={relayNum}
										className={classnames(
											classes.settingList,
											settings && settings.relayNum !== relayNum
												? classes.changed : null
										)}
										onChange={handleChangeRelayNum}
									>
										<option value='3'>３回</option>
										<option value='5'>５回</option>
										<option value='7'>７回</option>
										<option value='9'>９回</option>
									</select>
								</div>
								<div className={classes.settingItem}>
									<div className={classes.settingLabel}>コール優先順位の設定</div>
									<select
										value={callPriority}
										className={classnames(
											classes.settingList,
											settings && settings.callPriority !== callPriority
												? classes.changed : null
										)}
										onChange={handleChangeCallPriority}
									>
										<option value='0'>均等</option>
										<option value='1'>ランダム</option>
										<option value='2'>room順</option>
									</select>
								</div>

							</div>
						</div>
						<div className={classes.settingWarning}>※全員離席状態時に更新されます。</div>
					</div>
				</div>
			</div>
		</>
	);

};

HomeAdminView.propTypes =
{
	classes                 : PropTypes.object.isRequired,
	setAdminUsageDialogOpen : PropTypes.func.isRequired,
	setAdminUser            : PropTypes.func.isRequired,
	setAdminCalls           : PropTypes.func.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setAdminUser : (admin) =>
		{
			dispatch(adminActions.setAdminUser(admin));
		},
		setAdminCalls : (calls) =>
		{
			dispatch(adminActions.setAdminCalls(calls));
		},
		setAdminUsageDialogOpen : (adminUsageDialogOpen) =>
		{
			dispatch(adminActions.setAdminUsageDialogOpen(adminUsageDialogOpen));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.admin === next.admin
			);
		}
	}
)(withStyles(styles)(HomeAdminView)));
