// React
import React, { useState, useEffect } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as adminActions from '../../../actions/adminActions';
import * as roomActions from '../../../actions/roomActions';

// Message

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

// Daysjs
import dayjs from 'dayjs';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const styles = (theme) =>
	({
		root : {
			display : 'flex',
			width   : '100%',
			height  : '100%',
			color   : 'var(--text-color)'
		},
		dialogRoot : {
			// pointerEvents : 'none'
		},
		dialogPaper : {
			padding                        : '1% 2%',
			width                          : '45vw',
			pointerEvents                  : 'auto',
			[theme.breakpoints.down('lg')] : {
				width : '45vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] : {
				width : '90vw'
			}
		},
		inputGroup : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		icon : {
			width       : 'auto',
			height      : '1.5rem',
			marginRight : '0.5rem'
		},
		header : {
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'center',
			marginBottom   : '0.5rem',
			color          : 'var(--text-color)'
		},
		summery : {
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'flex-start',
			marginBottom   : '0.5rem',
			color          : 'var(--text-color)'
		},
		summeryItem : {
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'flex-start'
		},
		summeryLabel : {
			fontSize   : '0.8rem',
			whiteSpace : 'nowrap',
			marginTop  : '0.5rem'
		},
		summeryLabelSmall : {
			fontSize   : '0.6rem',
			marginLeft : '0.5rem'
		},
		summeryValue : {
			fontSize        : '0.8rem',
			padding         : '0.5rem',
			backgroundColor : 'rgba(0,0,0,0.1)',
			color           : 'var(--text-color)',
			borderRadius    : '0.5rem',
			width           : '6rem',
			marginLeft      : '0.5rem',
			marginRight     : '0.5rem',
			textAlign       : 'center'
		},
		input : {
			width        : '80%',
			border       : 'none',
			marginBottom : '1.5rem'
		},
		scrollBar : {
			overflow               : 'auto',
			height                 : '100%',
			paddingRight           : '10px',
			'&::-webkit-scrollbar' :
			{
				background   : '#ccc',
				width        : '7px',
				borderRadius : '100px'
			},
			'&::-webkit-scrollbar-thumb' :
			{
				background   : '#888',
				borderRadius : '100px',
				'&:hover'    :
				{
					background : '#555'
				}
			}
		},
		wrapper : {
			width           : '100%',
			height          : 'calc(100% - 2.5rem)',
			backgroundColor : 'white',
			borderRadius    : '0.5rem',
			padding         : '3%',
			position        : 'relative',
			overflow        : 'auto',
			overflowX       : 'hidden'
		},
		tableBg : {
			display       : 'flex',
			position      : 'absolute',
			height        : '100%',
			width         : '100%',
			zIndex        : '0',
			pointerEvents : 'none',
			paddingRight  : 'calc(6% + 17px)'
		},
		tableBgLine : {
			height      : '100%',
			borderRight : '1px solid #ccc',
			zIndex      : 5
		},
		tableHeader : {
			display      : 'flex',
			background   : 'rgba(0,0,0,0.1)',
			borderRadius : '0.5rem 0.5rem 0rem 0rem',
			height       : '2.5rem'
		},
		tableFooter : {
			background   : 'rgba(0,0,0,0.1)',
			borderRadius : '0rem 0rem 0.5rem 0.5rem',
			height       : '1rem'
		},
		tableHeaderText : {
			fontSize  : '0.8rem',
			textAlign : 'center',
			padding   : '0.7rem',
			color     : '#999898'
		},
		tableData : {
			display   : 'flex',
			listStyle : 'none',
			position  : 'relative',
			height    : '2.5rem'
		},
		tableDataValues : {
			display      : 'flex',
			border       : 'none',
			borderRadius : '0rem',
			background   : 'rgba(0,0,0,0.05)',
			alignItems   : 'center',
			zIndex       : 1,
			height       : '100%'
		},
		tableDataValuesOdd : {
			background : 'rgba(0,0,0,0.025)'
		},
		tableDataValue : {
			color     : '#888',
			textAlign : 'center',
			fontSize  : '0.8rem'
		},
		historyDate : {
			width : '50%'
		},
		historyDateContent : {
			width : '50%'
		},
		historyDuration : {
			width : '50%'
		},
		button : {
			backgroundColor : 'white',
			border          : '1px solid #ccc',
			borderRadius    : '0.5rem',
			padding         : '0.1rem',
			fontSize        : '0.8rem',
			width           : '2rem',
			textAlign       : 'center',
			boxShadow       : 'none',
			color           : 'var(--text-color)'
		}
	});

const theme = createTheme({
	overrides : {
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'hidden',
				padding        : '1rem 0'
			}
		},
		MuiDialogActions : {
			root : {
				flexDirection  : 'column',
				justifyContent : 'center'
			},
			spacing : {
				'& > :not(:first-child)' : {
					marginLeft : '0'
				}
			}
		},
		MuiButton : {
			label : {
				marginLeft  : '0.6rem',
				marginRight : '0.6rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				padding         : '0.4rem 0.8rem',
				border          : 'none',
				borderRadius    : '0.5rem',
				backgroundColor : 'var(--text-color)',
				boxShadow       : '2px 2px 4px inset var(--text-color)',
				color           : 'white'
			},
			notchedOutline : {
				border : 'none'
			}
		}
	}
});

const AdminUsageDialogSub = ({
	classes,
	setAdminUsageDialogSubOpen,
	adminUsageDialogSubDate,
	calls
}) =>
{
	const getMonthString = (timestamp) =>
	{
		const date = timestamp ? new Date(timestamp) : new Date();
		const month = (`0${date.getMonth() + 1}`).slice(-2);

		return `${date.getFullYear()}/${month}`;
	};

	const getDateString = (timestamp) =>
	{
		const date = timestamp ? new Date(timestamp) : new Date();
		const month = (`0${date.getMonth() + 1}`).slice(-2);
		const day = (`0${date.getDate()}`).slice(-2);

		return `${month}/${day}`;
	};

	const getTalkTimeString = (duration) =>
	{
		const seconds = parseInt(duration);
		const min = (`${parseInt(seconds/60)}`);
		const sec = (`0${parseInt(seconds%60)}`).slice(-2);

		return `${min}分${sec}秒`;
	};

	const thisMonth = getMonthString(adminUsageDialogSubDate);
	const [ callList, setCallList ] = useState([]);

	useEffect(() =>
	{
		if (adminUsageDialogSubDate)
		{
			const targetYear = dayjs(adminUsageDialogSubDate).year();
			const targetMonth = dayjs(adminUsageDialogSubDate).month();
			const list = [];

			const items = calls.filter((item) =>
			{
				const date = dayjs(item.timestamp);

				if (date.year() === targetYear && date.month() === targetMonth)
				{
					return true;
				}

				return false;
			});

			items.forEach((item) =>
			{
				list.push({
					timestamp : item.timestamp,
					duration  : item.duration
				});
			});

			setCallList(list);
		}
	}, [ adminUsageDialogSubDate, calls ]);

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				open
				onClose={() => setAdminUsageDialogSubOpen(false, 0)}
				classes={{
					root  : classes.dialogRoot,
					paper : classes.dialogPaper
				}}
				BackdropProps={{
					style : {
						backgroundColor : 'transparent'
					}
				}}
			>
				<DialogContent>
					<Box className={classes.header}>
						<img
							src='images/user_setting_icon.png'
							className={classes.icon}
							alt=''
						/>
						<Typography className={classes.title}>
							{thisMonth}
						</Typography>
					</Box>
					<Box className={classes.wrapper}>
						<Box className={classes.tableBg}>
							<Box className={`${classes.historyDate}`} />
							<Box className={`${classes.historyDuration}`} />
						</Box>
						<Box className={`${classes.scrollBar}`}>
							<ul>
								<Box className={classes.tableHeader}>
									<Box className={`${classes.tableBgLine} ${classes.tableHeaderText} ${classes.historyDate}`}>
										利用日
									</Box>
									<Box className={`${classes.tableHeaderText} ${classes.historyDuration}`}>
										通話時間
									</Box>
								</Box>
								{callList.length < 1 ? (
									<Box>No items</Box>
								) : (
									callList.map((item, index) =>
										(<li className={classes.tableData} key={index}>
											<Box position='absolute' width='100%' height='100%'>
												<Box className={`${classes.tableDataValues} ${index % 2 === 1 ? classes.tableDataValuesOdd : ''}`}>
													<Box className={`${classes.tableBgLine} ${classes.tableDataValue} ${classes.historyDateContent}`}>{getDateString(item.timestamp)}</Box>
													<Box className={`${classes.tableDataValue} ${classes.historyDuration}`}>{getTalkTimeString(item.duration)}</Box>
												</Box>
											</Box>
										</li>)
									))}
								<Box className={classes.tableFooter} />
							</ul>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions />
			</Dialog>
		</MuiThemeProvider>
	);
};

AdminUsageDialogSub.propTypes =
{
	classes                    : PropTypes.object.isRequired,
	setAdminUsageDialogSubOpen : PropTypes.func.isRequired,
	setIsLoading               : PropTypes.func.isRequired,
	adminUsageDialogSubDate    : PropTypes.any.isRequired,
	calls                      : PropTypes.array.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		adminUsageDialogSubDate : state.admin.adminUsageDialogSubDate,
		calls                   : state.admin.calls
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setAdminUsageDialogSubOpen : (adminUsageDialogSubOpen, date) =>
		{
			dispatch(adminActions.setAdminUsageDialogSubOpen(adminUsageDialogSubOpen, date));
		},
		setIsLoading : (flag) =>
		{
			dispatch(roomActions.setIsLoading(flag));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.admin.calls === next.admin.calls &&
				prev.admin === next.admin
			);
		}
	}
)(withStyles(styles)(AdminUsageDialogSub)));
