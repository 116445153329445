// React
import React, { useState } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as adminActions from '../../../actions/adminActions';
import * as roomActions from '../../../actions/roomActions';

// Message
import { FormattedMessage } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Firebase
import { functions } from '../../../lib/firebase';

const styles = (theme) =>
	({
		root : {
			display : 'flex',
			width   : '100%',
			height  : '100%'
		},
		dialogRoot : {
			pointerEvents : 'none'
		},
		dialogPaper : {
			padding                        : '1% 2.5%',
			width                          : '30vw',
			pointerEvents                  : 'auto',
			[theme.breakpoints.down('lg')] : {
				width : '40vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '60vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '75vw'
			},
			[theme.breakpoints.down('xs')] : {
				width : '90vw'
			}
		},
		header : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center',
			marginBottom  : '2rem'
		},
		icon : {
			width        : 'auto',
			height       : '2rem',
			marginBottom : '2rem'
		},
		title : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			fontWeight : 'bold'
		},
		inputGroup : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'row',
			alignItems    : 'center',
			paddingBottom : '1.5rem'
		},
		contentTitle : {
			width    : '35%',
			color    : 'var(--text-color)',
			fontSize : '0.8rem'
		},
		inputBg : {
			width           : '50%',
			border          : 'none',
			backgroundColor : 'var(--text-color)',
			borderRadius    : '0.5rem'
		},
		input : {
			width        : '50%',
			borderRadius : '0.5rem',
			border       : '1px solid var(--text-color)'
		},
		note : {
			width        : '100%',
			borderBottom : '1px solid var(--text-color)',
			marginBottom : '1.5rem'
		},
		noteTitle : {
			width                          : '100%',
			color                          : 'var(--text-color)',
			fontSize                       : '0.67rem',
			marginBottom                   : '0.7rem',
			[theme.breakpoints.down('lg')] : {
				fontSize : '0.6rem'
			},
			[theme.breakpoints.down('md')] : {
				fontSize : '0.7rem'
			},
			[theme.breakpoints.down('sm')] : {
				fontSize : '0.7rem'
			},
			[theme.breakpoints.down('xs')] : {
				fontSize : '0.7rem'
			}
		},
		warningGroup : {
			display      : 'flex',
			marginBottom : '1.5rem'
		},
		warningIcon : {
			width     : 'auto',
			height    : '2rem',
			marginTop : '3px'
		},
		warningContent : {
			color      : 'var(--text-color)',
			fontSize   : '0.67rem',
			marginLeft : '2rem'
		},
		button : {
			backgroundColor : 'var(--next-button-color)',
			marginBottom    : '2rem',
			'&:hover'       : {
				backgroundColor : 'var(--next-button-color)'
			}
		},
		errorMsg : {
			width     : '400px',
			height    : '1.2rem',
			color     : 'red',
			marginTop : '5px',
			fontSize  : '0.8rem',
			textAlign : 'center'
		}
	});

const theme = createTheme({
	overrides : {
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none',
				padding        : '1rem 0'
			}
		},
		MuiDialogActions : {
			root : {
				flexDirection  : 'column',
				justifyContent : 'center'
			},
			spacing : {
				'& > :not(:first-child)' : {
					marginLeft : '0'
				}
			}
		},
		MuiButton : {
			label : {
				marginLeft  : '0.8rem',
				marginRight : '0.8rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				padding      : '0.4rem 0.8rem',
				borderRadius : '0.5rem'
			},
			notchedOutline : {
				border : 'none'
			}
		}
	}
});

const AdminLoginConfirmDialog = ({
	user,
	classes,
	setChangePassSuccessDialogOpen,
	setIsLoading
}) =>
{
	const [ newPassword, setNewPassword ] = useState('');
	const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');
	const [ errorMsg, setErrorMsg ] = useState('');

	const changeAdminPasswordAPI = functions.httpsCallable('changeAdminPassword');

	const handleChangeNewPassword = (e) =>
	{
		setNewPassword(e.target.value);
	};

	const handleChangeNewPasswordConfirm = (e) =>
	{
		setNewPasswordConfirm(e.target.value);
	};

	const handleSubmit = async () =>
	{
		const reg = new RegExp(/^[a-zA-Z0-9]+$/);

		const uppercaseReg = new RegExp(/[A-Z]/);

		const emailStringArray = user.email.split('@');

		if (newPassword !== newPasswordConfirm)
		{
			setErrorMsg('パスワードと確認用パスワードが違います');

			return;
		}
		else if (reg.test(newPassword) === false)
		{
			setErrorMsg('使用可能な文字は半角英数字です。');

			return;
		}
		else if (newPassword.length < 8)
		{
			setErrorMsg('設定できるパスワードは8文字以上です。');

			return;
		}
		else if (uppercaseReg.test(newPassword) === false)
		{
			setErrorMsg('パスワードには大文字アルファベットを含む必要があります。');

			return;
		}
		else if (newPassword === emailStringArray[0])
		{
			setErrorMsg('管理者ログインID(メールアドレスの@より前の部分)が同一の文字列は設定できません');

			return;
		}
		else
		{
			setIsLoading(true);

			try
			{
				const result = await changeAdminPasswordAPI({
					id            : user.id,
					adminPassword : newPassword,
					email         : user.email
				});

				if (result.data)
				{
					setChangePassSuccessDialogOpen(true);
					setErrorMsg('');
				}
				else
				{
					setErrorMsg('エラーが発生しました');
				}
			}
			catch
			{
				setErrorMsg('エラーが発生しました');
			}

			setIsLoading(false);
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				open
				classes={{
					root  : classes.dialogRoot,
					paper : classes.dialogPaper
				}}
				BackdropProps={{
					style : {
						backgroundColor : 'transparent'
					}
				}}
			>
				<DialogTitle />
				<DialogContent>
					<Box className={classes.header}>
						<img
							src='images/user_setting_icon.png'
							className={classes.icon}
							alt=''
						/>
						<Typography className={classes.title}>
							アカウント情報の確認
						</Typography>
					</Box>
					<Box className={classes.inputGroup}>
						<Typography className={classes.contentTitle}>
							管理者ID
						</Typography>
						<TextField
							placeholder={user.email}
							variant='outlined'
							classes={{
								root : classes.inputBg
							}}
							inputProps={{
								classes : {
									input : classes.inputBg
								},
								style : {
									fontSize : '0.8rem',
									color    : 'white'
								}
							}}
							disabled
						/>
					</Box>
					<Box className={classes.inputGroup}>
						<Typography className={classes.contentTitle}>
							管理者パスワード
						</Typography>
						<TextField
							placeholder='＊＊＊＊＊＊＊＊＊＊＊＊'
							variant='outlined'
							classes={{
								root : classes.inputBg
							}}
							inputProps={{
								classes : {
									input : classes.inputBg
								},
								style : {
									fontSize : '0.8rem',
									color    : 'white'
								}
							}}
							disabled
						/>
					</Box>
					<Box className={classes.note}>
						<Typography className={classes.noteTitle}>
							パスワードを変更する場合は、新しいパスワードを入力の上、「変更」ボタンを押してください。
						</Typography>
					</Box>
					<Box className={classes.warningGroup}>
						<img
							src='images/caution_icon.png'
							className={classes.warningIcon}
							alt=''
						/>
						<ul type='disc' className={classes.warningContent}>
							<li>設定できるパスワードは 8 文字以上です。</li>
							<li>使用可能な文字は半角英数字です。</li>
							<li>1文字以上の英大文字を含む必要があります。</li>
							<li>管理者ログイン ID( メールアドレスの @ より前の部分 ) が同一の文字列は設定できません。</li>
							<li>セキュリティの観点より、他人に特定されやすい文字列は避けてください。 </li>
							<li>パスワードの変更は、お手続き後すぐに反映されます。</li>
						</ul>
					</Box>
					<Box className={classes.inputGroup}>
						<Typography className={classes.contentTitle}>
							新しいパスワード
						</Typography>
						<TextField
							variant='outlined'
							classes={{
								root : classes.input
							}}
							inputProps={{
								classes : {
									input : classes.input
								},
								style : {
									fontSize : '0.8rem',
									color    : 'var(--text-color)'
								}
							}}
							value={newPassword}
							onChange={handleChangeNewPassword}
						/>
					</Box>
					<Box className={classes.inputGroup}>
						<Typography className={classes.contentTitle}>
							新しいパスワード（確認）
						</Typography>
						<TextField
							variant='outlined'
							classes={{
								root : classes.input
							}}
							inputProps={{
								classes : {
									input : classes.input
								},
								style : {
									fontSize : '0.8rem',
									color    : 'var(--text-color)'
								}
							}}
							value={newPasswordConfirm}
							onChange={handleChangeNewPasswordConfirm}
						/>
					</Box>

					{ errorMsg &&
					<Typography className={classes.errorMsg}>
						{errorMsg}
					</Typography>
					}

				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						color='secondary'
						classes={{
							root : classes.button
						}}
						onClick={handleSubmit}
					>
						<FormattedMessage
							id='label.edit'
							defaultMessage='Edit'
						/>
					</Button>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

AdminLoginConfirmDialog.propTypes =
{
	user                           : PropTypes.object.isRequired,
	classes                        : PropTypes.object.isRequired,
	setChangePassSuccessDialogOpen : PropTypes.func.isRequired,
	setIsLoading                   : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		user : state.admin.user
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setChangePassSuccessDialogOpen : (changePassSuccessDialogOpen) =>
		{
			dispatch(adminActions.setChangePassSuccessDialogOpen(changePassSuccessDialogOpen));
		},
		setIsLoading : (flag) =>
		{
			dispatch(roomActions.setIsLoading(flag));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.admin.user === next.admin.user &&
				prev.admin.changePassSuccessDialogOpen === next.admin.changePassSuccessDialogOpen
			);
		}
	}
)(withStyles(styles)(AdminLoginConfirmDialog)));
