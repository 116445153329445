// React
import React from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as roomActions from '../../../actions/roomActions';

// Router
import { useHistory } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// Icon
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const styles = (theme) =>
	({
		root : {
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper : {
			padding                        : '1% 2%',
			width                          : '40vw',
			[theme.breakpoints.down('lg')] : {
				width : '40vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] : {
				width : '90vw'
			}
		},
		titleIcon : {
			width        : '3rem',
			height       : '3rem',
			marginBottom : '6%'
		},
		titleText : {
			color      : 'var(--text-color)',
			fontSize   : '0.8rem',
			fontWeight : 'bold',
			whiteSpace : 'break-spaces',
			textAlign  : 'center'
		},
		content : {
			color      : 'var(--text-color)',
			fontSize   : '0.8rem',
			whiteSpace : 'break-spaces',
			margin     : '2.5rem 0'
		},
		contentText : {
			marginBottom : '1.2rem'
		},
		permission : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.4rem',
			color        : '#0071bc',
			padding      : '0.1rem 0.8rem'
		},
		confirmBtn : {
			borderRadius    : '0.6rem',
			boxShadow       : '2px 2px 4px #aaa',
			backgroundColor : '#878686',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#878686'
			}
		}
	});

const theme = createTheme({
	overrides : {
		MuiDialogTitle : {
			root : {
				backgroundColor : 'transparent',
				color           : 'var(--text-color)',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiButton : {
			label : {
				margin : '0 0.8rem'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		}
	}
});

const NoticeJoinRoomDialog = ({
	noticeJoinRoomDialogOpen,
	setNoticeJoinRoomDialogOpen,
	classes,
	directMode
}) =>
{
	const history = useHistory();

	const handleClosePopup = () =>
	{
		setNoticeJoinRoomDialogOpen(false);
	};

	const submit = () =>
	{
		setNoticeJoinRoomDialogOpen(false);

		if (directMode)
		{
			history.push({
				pathname : '/',
				state    : { directMode: true }
			});
		}
		else
		{
			history.push({
				pathname : '/',
				state    : { directMode: false }
			});
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={noticeJoinRoomDialogOpen}
				onClose={handleClosePopup}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle id='form-dialog-title'>
					<Box
						display='flex'
						alignItems='center'
						flexDirection='column'
					>
						<ErrorOutlineIcon className={classes.titleIcon} />
						<Typography className={classes.titleText}>
							{'受付ボタンをクリックすると\nご利用のブラウザからカメラとマイクの利用許可について質問されます。'}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<ul type='disc' className={classes.content}>
						<li className={classes.contentText}>
							<p>マイク利用は必ず　<span className={classes.permission}>許可</span>　してください</p>
						</li>
						<li className={classes.contentText}>
							<p>
								{'カメラ利用は「キャンセル」を選択すると\n通話途中でカメラONにはできないのでご注意ください。'}
							</p>
						</li>
					</ul>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.confirmBtn}
						color='primary'
						onClick={() => submit()}
					>
						<FormattedMessage
							id='label.confirmed'
							defaultMessage='Confirmed'
						/>
					</Button>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

NoticeJoinRoomDialog.propTypes =
{
	noticeJoinRoomDialogOpen    : PropTypes.bool.isRequired,
	setNoticeJoinRoomDialogOpen : PropTypes.func.isRequired,
	classes                     : PropTypes.object.isRequired,
	directMode                  : PropTypes.bool
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = (state) =>
	{
		return {
			noticeJoinRoomDialogOpen : state.room.noticeJoinRoomDialogOpen
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setNoticeJoinRoomDialogOpen : (noticeJoinRoomDialogOpen) =>
		{
			dispatch(roomActions.setNoticeJoinRoomDialogOpen(noticeJoinRoomDialogOpen));
		}
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room.noticeJoinRoomDialogOpen === next.room.noticeJoinRoomDialogOpen
			);
		}
	}
)(withStyles(styles)(NoticeJoinRoomDialog)));