export const addLobbyPeer = (peerId) =>
	({
		type    : 'ADD_LOBBY_PEER',
		payload : { peerId }
	});

export const removeLobbyPeer = (peerId) =>
	({
		type    : 'REMOVE_LOBBY_PEER',
		payload : { peerId }
	});

export const setLobbyPeerDisplayName = (displayName, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_DISPLAY_NAME',
		payload : { displayName, peerId }
	});

export const setLobbyPeerPicture = (picture, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_PICTURE',
		payload : { picture, peerId }
	});

export const setLobbyPeerOS = (os, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_OS',
		payload : { os, peerId }
	});

export const setLobbyPeerBrowser = (browser, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_BROWSER',
		payload : { browser, peerId }
	});

export const setLobbyPeerGuestInputs = (guestInputs, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_GUEST_INPUTS',
		payload : { guestInputs, peerId }
	});

export const setLobbyPeerPromotionInProgress = (peerId, flag) =>
	({
		type    : 'SET_LOBBY_PEER_PROMOTION_IN_PROGRESS',
		payload : { peerId, flag }
	});