// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Component
import AdminLoginDialog from './AdminLoginDialog';
import AdminLoginInfoDialog from './AdminLoginInfoDialog';
import AdminLoginConfirmDialog from './AdminLoginConfirmDialog';
import ChangePassSuccessDialog from './ChangePassSuccessDialog';
import AdminUsageDialog from './AdminUsageDialog';
import AdminUsageDialogSub from './AdminUsageDialogSub';
import HomeAdminView from './HomeAdminView';
import ServiceStoppedPage from './ServiceStoppedPage';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as adminActions from '../../../actions/adminActions';

// Mui styles
import { withStyles } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// Icon
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';

// firebase
import { auth } from '../../../lib/firebase';

const styles = () =>
	({
		root : {
			width           : '100%',
			height          : '100%',
			backgroundColor : 'var(--background-color)',
			borderRadius    : '0px'
		},
		header : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between',
			padding        : '2%'
		},
		logo : {
			width        : 'auto',
			height       : '2.5rem',
			marginBottom : '1%'
		},
		seatButton : {
			position                 : 'absolute',
			top                      : '2%',
			right                    : '2%',
			backgroundColor          : '#e5e5e2',
			'& .MuiButton-contained' : {
				color           : 'var(--text-color)',
				backgroundColor : '#e5e5e2',
				boxShadow       : 'none',
				'&:hover'       : {
					color           : 'var(--text-color)',
					boxShadow       : 'none',
					backgroundColor : '#e5e5e2'
				}
			}
		},
		icon : {
			width  : '2rem',
			height : '2rem'
		}
	});

const AdminLoginPage = ({
	classes,
	admin,
	onClose,
	resetAdminView,
	serviceStopped
}) =>
{
	const changeViewMode = () =>
	{
		auth.signOut();
		resetAdminView();
		onClose();
	};

	const headerElement = (
		<>
			<Box className={classes.header}>
				<img
					alt='logo'
					src='images/main_logo.png'
					className={classes.logo}
				/>
				<Box className={classes.seatButton}>
					<Button
						variant='contained'
						endIcon={<InputOutlinedIcon className={classes.icon} />}
						onClick={() => changeViewMode()}
					>
						オペレータ画面ログイン
					</Button>
				</Box>
			</Box>
		</>
	);

	return (
		<>
			{/* notification of completing changing password */}
			{admin.changePassSuccessDialogOpen &&
			<>
				<Box className={classes.root}>
					{headerElement}
				</Box>
				<ChangePassSuccessDialog />
			</>
			}

			{ admin.adminLoginDialogOpen ?
				<>
					<Box className={classes.root}>
						{headerElement}
					</Box>
					<AdminLoginDialog />
				</>
				: admin.adminLoginInfoDialogOpen ?
					<>
						<Box className={classes.root}>
							{headerElement}
						</Box>
						<AdminLoginInfoDialog />
					</>
					: admin.adminLoginConfirmDialogOpen ?
						<>
							<Box className={classes.root}>
								{headerElement}
							</Box>
							<AdminLoginConfirmDialog />
						</>
						: serviceStopped ?
							<ServiceStoppedPage isAdmin changeViewMode={changeViewMode}/>
							:
							<Box className={classes.root}>
								{headerElement}
								{ admin.adminViewShown &&
									<HomeAdminView />
								}
								{ admin.adminUsageDialogOpen &&
									<AdminUsageDialog />
								}
								{ admin.adminUsageDialogSubOpen &&
									<AdminUsageDialogSub />
								}
							</Box>
			}

		</>
	);
};

AdminLoginPage.propTypes =
{
	classes        : PropTypes.object.isRequired,
	admin          : PropTypes.object.isRequired,
	onClose        : PropTypes.func,
	resetAdminView : PropTypes.func,
	serviceStopped : PropTypes.bool.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		admin          : state.admin,
		serviceStopped : state.me.serviceStopped
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		resetAdminView : () =>
		{
			dispatch(adminActions.resetAdminView());
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.admin.adminLoginDialogOpen === next.admin.adminLoginDialogOpen &&
				prev.admin.adminLoginInfoDialogOpen === next.admin.adminLoginInfoDialogOpen &&
				prev.admin.adminLoginConfirmDialogOpen ===
					next.admin.adminLoginConfirmDialogOpen &&
				prev.admin.changePassSuccessDialogOpen ===
					next.admin.changePassSuccessDialogOpen &&
				prev.admin.adminViewShown === next.admin.adminViewShown &&
				prev.admin.adminUsageDialogOpen === next.admin.adminUsageDialogOpen &&
				prev.admin.adminUsageDialogSubOpen === next.admin.adminUsageDialogSubOpen &&
				prev.me.serviceStopped === next.me.serviceStopped
			);
		}
	}
)(withStyles(styles)(AdminLoginPage)));
