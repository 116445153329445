import React from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Icons from './Icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const styles = (theme) =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			padding         : '1% 1%',
			width           : 400,
			height          : 300,
			aspectRatio     : 4/3,
			backgroundColor : '#666666'
		},
		lock :
		{
			padding : theme.spacing(2)
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleIcon :
		{
			width     : '4rem',
			height    : '4rem',
			transform : 'scaleX(-1)'
		},
		titleText :
		{
			color    : 'white',
			fontSize : '1.1rem'
		},
		promoteBtn :
		{
			width           : '40%',
			border          : '1px solid #8eba14',
			backgroundColor : '#8eba14',
			color           : 'white'
		},
		cancelBtn :
		{
			width           : '40%',
			border          : '1px solid #fff',
			backgroundColor : 'transparent',
			color           : 'white'
		},
		successIcon : {
			height       : '1.75rem',
			width        : '1.75rem',
			color        : 'white',
			marginBottom : '8px'
		}
	});

const theme = createTheme({
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'transparent'
			}
		},
		MuiDialogTitle : {
			root : {
				backgroundColor : 'transparent',
				color           : '#9ec317',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				color          : 'white',
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiTypography : {
			colorTextSecondary : {
				color : 'white'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.6rem'
			}
		}
	}
});

const ConfirmDialog = ({
	classes,
	show,
	accept,
	acceptArg,
	cancel,
	title,
	message,
	closeButtonOnly,
	success
}) =>
{

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={() => {}}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle id='form-dialog-title'>
					<div className={classes.title}>
						{ success ?
							<CheckCircleOutlineIcon color={'success'} className={classes.successIcon}/>
							:
							<Icons category={'host'} iconName={'alet'} type={'normal'} size={'1.75rem'}/>
						}
						<div className={classes.titleText}>{title}</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.cancelBtn}
						onClick={() => cancel()}
						color='primary'
					>
						<FormattedMessage
							id='label.close'
							defaultMessage='Close'
						/>
					</Button>

					{ !closeButtonOnly &&
						<Button
							className={classes.promoteBtn}
							onClick={acceptArg ? () => accept(acceptArg): accept}
							color='primary'
						>
							<FormattedMessage
								id='label.ok'
								defaultMessage='OK'
							/>
						</Button>
					}

				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

ConfirmDialog.propTypes =
{
	classes         : PropTypes.object.isRequired,
	show            : PropTypes.bool.isRequired,
	accept          : PropTypes.func.isRequired,
	acceptArg       : PropTypes.object,
	cancel          : PropTypes.func.isRequired,
	title           : PropTypes.string.isRequired,
	message         : PropTypes.string.isRequired,
	closeButtonOnly : PropTypes.bool,
	success         : PropTypes.bool
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(ConfirmDialog)));