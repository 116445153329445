import React, { useState, useEffect, useCallback } from 'react';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import isElectron from 'is-electron';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CookieConsent from 'react-cookie-consent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import AdminLoginPage from './AdminLoginPage';
import * as meActions from '../../../actions/meActions';
import * as roomActions from '../../../actions/roomActions';
import * as adminActions from '../../../actions/adminActions';

import { functions, auth } from '../../../lib/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

// Icon
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';

const styles = (theme) =>
	({
		root :
		{
			display              : 'flex',
			width                : '100%',
			height               : '100%',
			backgroundColor      : 'var(--background-color)',
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat'
		},
		dialogTitle :
		{
		},
		dialogPaper :
		{
			fontSize             : '0.8rem',
			padding              : theme.spacing(2),
			backgroundColor      : 'transparent',
			backgroundImage      : 'url("images/login_dialog_bg.png")',
			backgroundAttachment : 'local',
			backgroundPosition   : 'center',
			backgroundSize       : 'contain',
			backgroundRepeat     : 'no-repeat',
			boxShadow            : 'none'
		},
		icon :
		{
			width        : '35px',
			marginBottom : '20px'
		},
		input :
		{
			width        : '200px',
			border       : 'none',
			marginBottom : '60px',
			marginTop    : '10px'
		},
		message : {
			width        : '200px',
			height       : '1.2rem',
			color        : 'red',
			marginBottom : '30px',
			fontSize     : '0.6rem',
			textAlign    : 'center'
		},
		submitBtn :
		{
			width           : '120px',
			backgroundColor : '#9ec317',
			marginBottom    : '20px'
		},
		inputLabel :
		{
			fontSize  : '0.6rem',
			color     : '#999898',
			textAlign : 'center'
		},
		forgotPasswordLabel :
		{
			fontSize  : '0.6rem',
			color     : '#999898',
			textAlign : 'center'
		},
		forgotPasswordLink :
		{
			color : '#9ec317'
		},
		seatButton : {
			position        : 'absolute',
			top             : '2%',
			right           : '2%',
			zIndex          : 10000,
			backgroundColor : 'transparent',
			boxShadow       : 'none',
			color           : 'var(--text-color)',
			'&:hover'       : {
				boxShadow       : 'none',
				backgroundColor : 'transparent'
			}
		},
		btnIcon : {
			width  : '2rem',
			height : '2rem'
		}
	});

const DialogTitle = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
	root :
	{
		padding    : theme.spacing(2),
		width      : '405px',
		height     : '550px',
		marginLeft : '137px',
		display    : 'flex'
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogActions);

const theme = createTheme({
	overrides : {
		PrivateNotchedOutline : {
			root : {
				borderRadius : '0.5rem',
				border       : 'none',
				boxShadow    : '2px 2px 4px inset #999898'
			}
		},
		MuiOutlinedInput : {
			input : {
				padding         : '0.3rem 0.5rem',
				border          : 'none',
				borderRadius    : '0.5rem',
				backgroundColor : '#999898',
				boxShadow       : '2px 2px 4px inset #999898',
				color           : 'white'
			},
			notchedOutline : {
				border : 'none'
			}
		}
	}
});

const LoginDialog = ({
	classes,
	setUser,
	setIsLoading,
	resetAdminView
}) =>
{
	const intl = useIntl();
	const [ password, setPassword ] = useState('');
	const [ isAdmin, setIsAdmin ] = useState(false);
	const [ message, setMessage ] = useState('');
	const [ admin ] = useAuthState(auth);

	const handleChangePassword = (event) =>
	{
		setPassword(event.currentTarget.value);
	};

	const handleLogin = useCallback(async () =>
	{
		const parts = window.location.hostname.split('.');
		const subdomain = parts.shift();
		const userLoginAPI = functions.httpsCallable('userLogin');

		setIsLoading(true);

		const result = await userLoginAPI({
			password  : password,
			subdomain : subdomain
		});

		setIsLoading(false);

		if (result.data)
		{
			setUser(result.data.user);
		}
		else
		{
			setMessage('ログインに失敗しました。パスワードを確認してください。');
		}
	},
	[
		setIsLoading,
		setUser,
		setMessage,
		password
	]);

	// at admin page, request login everytime 
	const toggleAdminView = async () =>
	{
		if (admin)
		{
			await auth.signOut();
		}
		setIsAdmin(!isAdmin);
		resetAdminView();
	};

	const loginByKeydown = useCallback((e) =>
	{

		if (e.keyCode === 13 && !isAdmin)
		{
			handleLogin();
		}
	}, [ handleLogin, isAdmin ]);

	useEffect(() =>
	{
		document.addEventListener('keydown', loginByKeydown);

		return () =>
		{
			// Clean up the subscription
			document.removeEventListener('keydown', loginByKeydown);
		};
	}, [ loginByKeydown ]);

	return (
		<>
			{ !isAdmin ?
				<MuiThemeProvider theme={theme}>
					<Box className={classes.root}>
						<Button
							variant='contained'
							classes={{
								root : classes.seatButton
							}}
							endIcon={<InputOutlinedIcon className={classes.btnIcon} />}
							onClick={() => toggleAdminView()}
						>
							管理者画面ログイン
						</Button>
						<Dialog
							open
							classes={{
								paper : classes.dialogPaper
							}}
							BackdropProps={{
								style : {
									backgroundColor : '#e4e4e1'
								}
							}}
						>
							<DialogTitle/>

							<DialogContent>
								<Grid
									container
									direction='column'
									justifyContent='center'
									alignItems='center'
								>
									<Grid item>
										<img className={classes.icon} alt='icon' src='images/lock_icon.png'/>
									</Grid>

									<Grid item>
										<Typography className={classes.inputLabel}>
											ログインパスワード
										</Typography>
									</Grid>

									<Grid item>
										<TextField
											autoFocus
											id='password'
											placeholder={intl.formatMessage({
												id             : 'label.password',
												defaultMessage : 'Password'
											})}
											value={password}
											variant='outlined'
											name='password'
											type='password'
											required
											classes={{
												root : classes.input
											}}
											inputProps={{
												classes : {
													input : classes.input
												},
												style : {
													textAlign : 'center',
													fontSize  : '0.8rem'
												}
											}}
											onChange={(event) => handleChangePassword(event)}
										/>
									</Grid>

									<Grid item>
										<Typography className={classes.message}>
											{message}
										</Typography>
									</Grid>

									<Grid item>
										<Button
											variant='contained'
											color='secondary'
											classes={{
												root : classes.submitBtn
											}}
											onClick={handleLogin}
										>
											<FormattedMessage
												id='label.login'
												defaultMessage='Login'
											/>
										</Button>
									</Grid>

									<Grid item>
										<Typography className={classes.forgotPasswordLabel}>
											パスワードをお忘れの方は<br/>管理者にお問い合わせください
										</Typography>
									</Grid>
								</Grid>
							</DialogContent>

							<DialogActions />

							{ !isElectron() &&
								<CookieConsent
									buttonText={intl.formatMessage({
										id             : 'room.consentUnderstand',
										defaultMessage : 'I understand'
									})}
									style={{ display: 'none' }}
								>
									<FormattedMessage
										id='room.cookieConsent'
										defaultMessage='This website uses cookies to enhance the user experience'
									/>
								</CookieConsent>
							}
						</Dialog>
					</Box>
				</MuiThemeProvider>
				:
				<AdminLoginPage onClose={toggleAdminView}/>
			}
		</>
	);
};

LoginDialog.propTypes =
{
	classes        : PropTypes.object.isRequired,
	setUser        : PropTypes.func.isRequired,
	setIsLoading   : PropTypes.func.isRequired,
	resetAdminView : PropTypes.func
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setUser : (user) =>
		{
			dispatch(meActions.setUser(user));
		},
		setIsLoading : (flag) =>
		{
			dispatch(roomActions.setIsLoading(flag));
		},
		resetAdminView : () =>
		{
			dispatch(adminActions.resetAdminView());
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(LoginDialog)));
