// React
import React from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as adminActions from '../../../actions/adminActions';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const styles = () =>
	({
		root : {
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper : {
			padding : '1% 3%'
		},
		titleIcon : {
			width        : 'auto',
			height       : '2.5rem',
			marginBottom : '10%'
		},
		titleText : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			fontWeight : 'bold'
		},
		contentText : {
			color    : 'var(--text-color)',
			fontSize : '0.7rem'
		},
		cancelBtn : {
			width           : '4rem',
			height          : '4rem',
			borderRadius    : '4rem',
			boxShadow       : '2px 2px 4px #aaa',
			backgroundColor : 'var(--submit-button-color)',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : 'var(--hover-button-color)'
			}
		}
	});

const theme = createTheme({
	overrides : {
		MuiDialogTitle : {
			root : {
				backgroundColor : 'transparent',
				color           : 'var(--text-color)',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		}
	}
});

const ChangePassSuccessDialog = ({
	changePassSuccessDialogOpen,
	resetAdminView,
	classes
}) =>
{

	const handleClosePopup = () =>
	{
		resetAdminView();
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={changePassSuccessDialogOpen}
				onClose={handleClosePopup}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle id='form-dialog-title'>
					<Box
						display='flex'
						alignItems='center'
						flexDirection='column'
					>
						<img
							src='images/pass_setting_icon.png'
							className={classes.titleIcon}
							alt=''
						/>
						<Typography className={classes.titleText}>
							<FormattedMessage
								id='admin.changePassSuccessTitle'
								defaultMessage='Change administrator login password'
							/>
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<DialogContentText className={classes.contentText}>
						<FormattedMessage
							id='admin.changePassSuccessContent'
							defaultMessage='The password change is complete.'
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.cancelBtn}
						onClick={handleClosePopup}
						color='primary'
					>
						<FormattedMessage
							id='label.close'
							defaultMessage='Close'
						/>
					</Button>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

ChangePassSuccessDialog.propTypes =
{
	changePassSuccessDialogOpen : PropTypes.bool.isRequired,
	resetAdminView              : PropTypes.func.isRequired,
	classes                     : PropTypes.object.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = (state) =>
	{
		return {
			changePassSuccessDialogOpen : state.admin.changePassSuccessDialogOpen
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		resetAdminView : () =>
		{
			dispatch(adminActions.resetAdminView());
		}
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.admin.changePassSuccessDialogOpen === next.admin.changePassSuccessDialogOpen
			);
		}
	}
)(withStyles(styles)(ChangePassSuccessDialog)));