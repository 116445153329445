/**
 * 外部ライブラリ等の設定用ファイル
 */
import * as Const from '../const';

function firebaseConfig(env)
{
	switch (env)
	{
		case Const.ENV_DEVELOP:
			return {
				apiKey            : 'AIzaSyDmc319wnOaeELMWFJo_UJCp4z_diZ9joY',
				authDomain        : 'nddoor-ec22e.firebaseapp.com',
				projectId         : 'nddoor-ec22e',
				storageBucket     : 'nddoor-ec22e.appspot.com',
				messagingSenderId : '3613643160',
				appId             : '1:3613643160:web:fe302ed92762eb92849bed',
				measurementId     : 'G-BP2BCB1LMT'
			};

		case Const.ENV_STAGING:
			return {
				apiKey            : 'AIzaSyDmc319wnOaeELMWFJo_UJCp4z_diZ9joY',
				authDomain        : 'nddoor-ec22e.firebaseapp.com',
				projectId         : 'nddoor-ec22e',
				storageBucket     : 'nddoor-ec22e.appspot.com',
				messagingSenderId : '3613643160',
				appId             : '1:3613643160:web:fe302ed92762eb92849bed',
				measurementId     : 'G-BP2BCB1LMT'
			};

		case Const.ENV_PRODUCTION:
			return {
				apiKey            : 'AIzaSyDmc319wnOaeELMWFJo_UJCp4z_diZ9joY',
				authDomain        : 'nddoor-ec22e.firebaseapp.com',
				projectId         : 'nddoor-ec22e',
				storageBucket     : 'nddoor-ec22e.appspot.com',
				messagingSenderId : '3613643160',
				appId             : '1:3613643160:web:fe302ed92762eb92849bed',
				measurementId     : 'G-BP2BCB1LMT'
			};

		default:
			return {
				apiKey            : 'AIzaSyDmc319wnOaeELMWFJo_UJCp4z_diZ9joY',
				authDomain        : 'nddoor-ec22e.firebaseapp.com',
				projectId         : 'nddoor-ec22e',
				storageBucket     : 'nddoor-ec22e.appspot.com',
				messagingSenderId : '3613643160',
				appId             : '1:3613643160:web:fe302ed92762eb92849bed',
				measurementId     : 'G-BP2BCB1LMT'
			};
	}
}

function googleAnalyticsConfig(env)
{
	switch (env)
	{
		case Const.ENV_DEVELOP:
			return '';

		case Const.ENV_STAGING:
			return 'UA-195338529-1';

		case Const.ENV_PRODUCTION:
			return 'UA-196910415-1';
		default :
			return 'UA-196910415-1';
	}
}

export const Config = {
	firebase        : firebaseConfig(process.env.NODE_ENV) || {},
	googleAnalytics : googleAnalyticsConfig(process.env.NODE_ENV)
};
