export function createNewMessage(text, sender, name, picture)
{
	return {
		type : 'message',
		text,
		time : Date.now(),
		name,
		sender,
		picture
	};
}

export function createNewFileShareMessage(
	sender,
	name,
	picture,
	fileName,
	fileShareUrl,
	error
)
{

	return {
		type : 'file',
		time : Date.now(),
		name,
		sender,
		picture,
		fileName,
		fileShareUrl,
		error
	};
}