// React
import React, { useState, useEffect, useRef } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as adminActions from '../../../actions/adminActions';
import * as roomActions from '../../../actions/roomActions';

// Message
import { FormattedMessage } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

// Firebase
import { functions } from '../../../lib/firebase';

const styles = (theme) =>
	({
		root : {
			display : 'flex',
			width   : '100%',
			height  : '100%'
		},
		dialogRoot : {
			pointerEvents : 'none'
		},
		dialogPaper : {
			padding                        : '1% 2%',
			width                          : '20vw',
			pointerEvents                  : 'auto',
			[theme.breakpoints.down('lg')] : {
				width : '30vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] : {
				width : '90vw'
			}
		},
		header : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center',
			marginBottom  : '2rem'
		},
		icon : {
			width        : 'auto',
			height       : '2rem',
			marginBottom : '2rem'
		},
		title : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			fontWeight : 'bold'
		},
		inputGroup : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		contentTitle : {
			color        : 'var(--text-color)',
			fontSize     : '0.7rem',
			marginBottom : '0.6rem'
		},
		inputBg : {
			width           : '80%',
			border          : 'none',
			marginBottom    : '1.5rem',
			backgroundColor : 'var(--text-color)',
			borderRadius    : '0.5rem'
		},
		input : {
			width        : '80%',
			marginBottom : '1.5rem',
			borderRadius : '0.5rem',
			border       : '1px solid var(--text-color)'
		},
		codes : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		inputCode : {
			width        : '2rem',
			height       : '2.5rem',
			margin       : '0 2%',
			borderRadius : '0.5rem',
			border       : '1px solid var(--text-color)'
		},
		button : {
			backgroundColor : 'var(--next-button-color)',
			marginTop       : '1rem',
			marginBottom    : '2rem',
			width           : '7rem',
			'&:hover'       : {
				backgroundColor : 'var(--next-button-color)'
			}
		},
		cancelButton : {
			backgroundColor : 'var(--button-color)',
			marginTop       : '1rem',
			marginBottom    : '2rem',
			width           : '7rem',
			color           : 'var(--text-color)',
			'&:hover'       : {
				backgroundColor : 'var(--next-button-color)',
				color           : '#fff'
			}
		},
		control : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-evenly'
		},
		errorMsg : {
			width     : '400px',
			height    : '1.2rem',
			color     : 'red',
			marginTop : '5px',
			fontSize  : '0.8rem',
			textAlign : 'center'
		},
		errorMsgCodeView : {
			marginTop : '20px'
		}
	});

const theme = createTheme({
	overrides : {
		MuiBox : {
			root : {
				whiteSpace : 'nowrap'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none',
				padding        : '1rem 0'
			}
		},
		MuiDialogActions : {
			root : {
				flexDirection  : 'column',
				justifyContent : 'center'
			},
			spacing : {
				'& > :not(:first-child)' : {
					marginLeft : '0'
				}
			}
		},
		MuiButton : {
			label : {
				marginLeft  : '0.8rem',
				marginRight : '0.8rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				padding      : '0.4rem 0.8rem',
				borderRadius : '0.5rem'
			},
			notchedOutline : {
				border : 'none'
			}
		}
	}
});

const AdminLoginInfoDialog = ({
	user,
	classes,
	setAdminLoginDialogOpen,
	setAdminLoginInfoDialogOpen,
	setAdminLoginConfirmDialogOpen,
	setIsLoading,
	setAdminUser
}) =>
{

	const [ placeholder, setPlaceholder ] = useState('');
	const [ userTell, setUserTell ] = useState('');
	const [ isCodeValidateView, setIsCodeValidateView ] = useState(false);
	const [ code, setCode ] = useState([ '', '', '', '', '', '' ]);
	const [ errorMsg, setErrorMsg ] = useState('');

	const requestSmsAuthAPI = functions.httpsCallable('requestSmsAuth');
	const checkSmsAuthAPI = functions.httpsCallable('checkSmsAuth');

	const handleSubmit = async () =>
	{
		if (!user)
		{
			return;
		}

		if (!isCodeValidateView)
		{
			const tell = userTell.replace(/-/g, '');

			setIsLoading(true);

			const result = await requestSmsAuthAPI({
				id   : user.id,
				tell : tell
			});

			setIsLoading(false);

			if (result.data)
			{
				setIsCodeValidateView(true);
				setErrorMsg('');
			}
			else
			{
				setErrorMsg('送信できませんでした。電話番号をお確かめください。');
			}
		}
		else
		{
			setIsLoading(true);

			const result = await checkSmsAuthAPI({
				id   : user.id,
				code : code.join('')
			});

			setIsLoading(false);

			if (result.data)
			{
				setAdminLoginConfirmDialogOpen(true);
				setAdminLoginInfoDialogOpen(false);
				setErrorMsg('');
			}
			else
			{
				setErrorMsg('SMSコードが正しくありません。');
			}
		}
	};

	const handleChangeUserTell = (e) =>
	{
		setUserTell(e.target.value);
	};

	const inputRefs = useRef([]);

	const handleChangeCode = (index, e) =>
	{
		code[index] = e.target.value;
		setCode([ ...code ]);

		if ((index + 1 !== code.length) && e.target.value)
		{
			const nextIndex = index + 1;

			inputRefs.current[nextIndex].focus();
		}
	};

	const handleCancel = () =>
	{
		setAdminLoginDialogOpen(true);
		setAdminLoginConfirmDialogOpen(false);
		setAdminLoginInfoDialogOpen(false);
	};

	const fetchAdminUser = async () =>
	{
		const parts = window.location.hostname.split('.');
		const subdomain = parts.shift();
		const requestAdminHintAPI = functions.httpsCallable('requestAdminHint');

		setIsLoading(true);

		const result = await requestAdminHintAPI({
			subdomain : subdomain
		});

		setIsLoading(false);

		if (result.data)
		{
			setAdminUser(result.data.user);
			setPlaceholder(`＊＊＊-＊＊＊＊-${result.data.user.tell.slice(-4)}`);
		}
	};

	useEffect(() =>
	{
		fetchAdminUser();
	}, []);

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				open
				classes={{
					root  : classes.dialogRoot,
					paper : classes.dialogPaper
				}}
				BackdropProps={{
					style : {
						backgroundColor : 'transparent'
					}
				}}
			>
				<DialogTitle />
				<DialogContent>
					{ !isCodeValidateView ?
						<>
							<Box className={classes.header}>
								<img
									src='images/user_setting_icon.png'
									className={classes.icon}
									alt=''
								/>
								<Typography className={classes.title}>
									アカウント情報の確認
								</Typography>
								<Typography className={classes.contentTitle}>
									{'<SMS 二段階認証>'}
								</Typography>
							</Box>
							<Box className={classes.inputGroup}>
								<Typography className={classes.contentTitle}>
									登録した電話番号に再設定用コードを送信します。
								</Typography>
								<TextField
									placeholder={placeholder}
									variant='outlined'
									classes={{
										root : classes.inputBg
									}}
									inputProps={{
										classes : {
											input : classes.inputBg
										},
										style : {
											textAlign : 'center',
											fontSize  : '0.75rem',
											color     : 'white'
										}
									}}
									disabled
								/>
							</Box>
							<Box className={classes.inputGroup}>
								<Typography className={classes.contentTitle}>
									上記の電話番号を入力の上、
								</Typography>
								<Typography className={classes.contentTitle}>
									「送信」ボタンを押してください。
								</Typography>
								<TextField
									variant='outlined'
									classes={{
										root : classes.input
									}}
									inputProps={{
										classes : {
											input : classes.input
										},
										style : {
											textAlign : 'center',
											fontSize  : '0.75rem',
											color     : 'var(--text-color)'
										}
									}}
									value={userTell}
									onChange={handleChangeUserTell}
								/>

								{ errorMsg &&
								<Typography className={classes.errorMsg}>
									{errorMsg}
								</Typography>
								}
							</Box>
						</>
						:
						<>
							<Box className={classes.header}>
								<img
									src='images/user_setting_icon.png'
									className={classes.icon}
									alt=''
								/>
								<Typography className={classes.title}>
									SMSコードの確認
								</Typography>
								<Typography className={classes.contentTitle}>
									{'<SMS 二段階認証>'}
								</Typography>
							</Box>
							<Box className={classes.inputGroup}>
								<Typography className={classes.contentTitle}>
									入力した電話番号に送信されたコードを入力してください
								</Typography>
								<Box className={classes.codes}>
									{ code.map((value, index) =>
									{
										return (
											<TextField
												autoComplete='off'
												key={index}
												inputRef={(el) =>
												{ inputRefs.current[index] = el; }}
												variant='outlined'
												classes={{
													root : classes.inputCode
												}}
												inputProps={{
													classes : {
														input : classes.input
													},
													style : {
														fontSize  : '1rem',
														padding   : '0.5rem 0',
														textAlign : 'center',
														color     : 'var(--text-color)'
													},
													maxLength : 1
												}}
												value={code[index]}
												onChange={(e) => handleChangeCode(index, e)}
											/>
										);
									})}
								</Box>

								{ errorMsg &&
								<Typography className={`${classes.errorMsg} ${classes.errorMsgCodeView}`}>
									{errorMsg}
								</Typography>
								}
							</Box>
						</>
					}
				</DialogContent>
				<DialogActions>
					<Box className={classes.control}>
						<Button
							variant='contained'
							color='secondary'
							classes={{
								root : classes.button
							}}
							onClick={handleSubmit}
						>
							<FormattedMessage
								id='label.send'
								defaultMessage='Send'
							/>
						</Button>
						<Button
							variant='contained'
							color='secondary'
							classes={{
								root : classes.cancelButton
							}}
							onClick={handleCancel}
						>
							<FormattedMessage
								id='label.back'
								defaultMessage='Back'
							/>
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

AdminLoginInfoDialog.propTypes =
{
	user                           : PropTypes.object,
	classes                        : PropTypes.object.isRequired,
	setAdminLoginInfoDialogOpen    : PropTypes.func.isRequired,
	setAdminLoginConfirmDialogOpen : PropTypes.func.isRequired,
	setAdminLoginDialogOpen        : PropTypes.func.isRequired,
	setIsLoading                   : PropTypes.func.isRequired,
	setAdminUser                   : PropTypes.func.isRequired
};

const mapStateToProps = (state) =>
{
	return {
		user : state.admin.user
	};
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setAdminLoginDialogOpen : (adminLoginDialogOpen) =>
		{
			dispatch(adminActions.setAdminLoginDialogOpen(adminLoginDialogOpen));
		},
		setAdminLoginInfoDialogOpen : (adminLoginInfoDialogOpen) =>
		{
			dispatch(adminActions.setAdminLoginInfoDialogOpen(adminLoginInfoDialogOpen));
		},
		setAdminLoginConfirmDialogOpen : (adminLoginConfirmDialogOpen) =>
		{
			dispatch(adminActions.setAdminLoginConfirmDialogOpen(adminLoginConfirmDialogOpen));
		},
		setIsLoading : (flag) =>
		{
			dispatch(roomActions.setIsLoading(flag));
		},
		setAdminUser : (user) =>
		{
			dispatch(adminActions.setAdminUser(user));
		}
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.admin.user === next.admin.user
			);
		}
	}
)(withStyles(styles)(AdminLoginInfoDialog)));
